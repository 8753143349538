.catalog {
  &-header {
    background-color: $bg;
    color: $border;
    margin-bottom: $padding_sm;
    align-items: center;
    display: flex;
    @include media(hover,768){
      padding: 5px 0;
      &.show-more-hidden {
        display: flex !important;
      }
    }
    @include media(screen,767){
      padding: 0 10px;
      &.show-more-hidden {
        display: none;
      }
    }
    .filter {
      display: flex;
      align-items: center;
      height: 30px;
      @include media(hover,768){
        margin: 0 0 0 $padding_sm;
      }
      @include media(screen,767){
        width: 100%;
        justify-content: space-between;
        &:not(:last-child) {
          border-bottom: 1px solid $border;
        }
      }
      .arrow {
        width: 30px;
        height: 30px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-color: currentColor;
          border-width: 2px;
          border-style: solid solid none none;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transform: rotate(135deg) translate(-1px,1px);
          transition: transform 0.3s;
        }
      }
      &.up {
        .arrow {
          &:after {
            transform: rotate(315deg) translate(-3px,3px);
          }
        }
      }
    }
    @include media(screen,1024){
      text-transform: uppercase;
    }
    @include breakpoints(768,1024){
      font-size: 3rem;
    }
    @include media(screen,767){
      flex-direction: column;
      font-size: 3.5rem;
    }
  }
  &-main {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
  }
  &-filters {
    background-color: $bg;
    //margin-bottom: 2*$padding_sm;
    @include media(hover,1201){
      &.scrolled {
        padding: $padding_sm $padding_sm $padding_sm $padding_md;
        max-height: 100%;
        @include media(screen,1200){

        }
      }
      &.dynamic {
        //max-height: none;
        transition: all 0.2s;
        height: calc(100vh - 220px);
        .header.minimized ~ .main .fixed & {
          transform: translate3d(0,-40px,0);
          //height: calc(100vh - 140px);
        }
        .header.minimized ~ .main .bottom & {
          transform: translate3d(0,0,0);
        }
      }
    }

    &--btn {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    &--container {
      position: relative;
      height: 100%;
      width: 100%;
      padding-left: $padding_sm;
      &[data-catalog="project"]{
        padding-top: $padding_sm;
        padding-bottom: 2*$padding_md;
      }
      &[data-catalog="partners"]{
        padding-bottom: 2*$padding_md;
        @include media(hover,1201){
          padding-top: 80px;
        }
        @include breakpoints(768,1200){
          padding-top: 70px;
        }
        @include media(screen,767){
          padding-top: 60px;
        }
      }
      .actions {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    form {
      height: 100%;
      width: 100%;
    }
    &--form {
      padding-right: $padding_sm;
      height: 100%;
      width: 100%;
      @include media(hover,1201){
        overflow: hidden;
      }
      @include media(screen,1200){
        overflow: auto;
        .portrait & {
          max-height: 50vh;
        }
        .landscape & {
          max-height: 80vh;
        }
      }
    }
    /*&--wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      @include media(hover,1201){
        .catalog-filters {
          max-height: calc(100% - 60px);
        }
      }
    }*/
    &--head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .show-more {
        width: 30px;
        height: 30px;
        position: relative;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          width: 9px;
          height: 9px;
          border-color: $text_lt;
          border-width: 2px;
          border-style: solid solid none none;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transform: rotate(135deg) translate(-2px,2px);
          transition: all 0.3s;
          .show & {
            transform: rotate(315deg) translate(-2px,2px);
          }
        }
      }
    }
    .reset {
      width: 50%;
    }
    .submit {
      width: 50%;
    }
    .actions {
      display: flex;
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(screen,767){
      flex-direction: column;
      & > * {
        &:not(:last-child){
          margin-bottom: $padding_sm;
        }
      }
    }
  }
  .btn-box {
    @include media(hover,768){
      margin-bottom: 0;
    }
    @include media(screen,767){
      margin-bottom: $padding_sm;
    }
  }
}

.filter-row {
  @include media(hover,1201){
    .btn-box {display: none;}
  }
  @include media(screen,1200){
    .btn-box {
      margin: 0;
    }
    .custom-row-col.col-sidebar {
      width: 100%;
      margin-bottom: $padding_sm;
    }
  }
}