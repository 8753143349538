.header {
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  transition: all 0.2s;
  &-top {
    background-color: $text;
    color: $text_lt;
    @include media(screen,1200){
      display: none;
    }
    &--wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
    }
    &--telephone {
      color: $white;
      font-size: 4rem;
      margin-right: $padding_md;
    }
    &--nav {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 3.5rem;
      nav {
        ul {
          display: flex;
          align-items: center;
        }
        li {
          position: relative;
          height: 40px;
          display: flex;
          align-items: center;
          margin-right: $padding_md;
          &.has-drop {
            @include hover(){
              & > a {
                color: $white;
              }
              .header-top--drop {
                opacity: 1;
                pointer-events: auto;
                margin-top: 0;
                .minimized & {
                  opacity: 0;
                  pointer-events: none;
                }
              }
            }
          }
        }
        a {
          @include hover(){
            color: $white;
          }
        }
      }
    }
    &--drop {
      transition: all 0.2s;
      pointer-events: none;
      opacity: 0;
      margin-top: -10px;
      position: absolute;
      top: 100%;
      left: 50%;
      background-color: $text;
      color: $text_lt;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      padding: 10px $padding_sm $padding_sm;
      transform: translateX(-50%);
      a {
        padding: 8px;
      }
    }
    &--search {
      width: 16px;
      height: 16px;
      display: block;
      margin: 0 10px;
      .fieldset {
        position: absolute;
        left: -5px;
        right: 0;
        top: 50%;
        height: 24px;
        margin-top: -12px;
        transition: all 0.3s;
        opacity: 0;
        pointer-events: none;
      }
      .input {
        width: 100%;
        height: 100%;
        background-color: $text;
        color: $white;
        border: 1px solid $border;
        border-radius: 30px;
        padding: 0 $padding_sm;
        font-size: 3rem;
        outline: none;

      }
      .label {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        cursor: pointer;
        margin-top: -8px;
      }
      .submit {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -8px;
        pointer-events: none;
        opacity: 0;
        background: transparent;
        border: none;
      }
      .active-search {
        .fieldset {
          opacity: 1;
          pointer-events: auto;
        }
        .submit {
          pointer-events: auto;
        }
      }
    }
  }
  &-main {
    position: relative;
    background-color: $white;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 10px;
      background: rgba($border,0.16);
      background: -moz-linear-gradient(top, rgba($border,0.16) 0%, rgba($border,0) 100%);
      background: -webkit-linear-gradient(top, rgba($border,0.16) 0%,rgba($border,0) 100%);
      background: linear-gradient(to bottom, rgba($border,0.16) 0%,rgba($border,0) 100%);
    }
    &--wrap {
      transition: all 0.2s;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media(hover,1201){
        height: 100px;
      }
      @include media(screen,1200){
        height: 60px;
      }
    }
    &--logo {
      @include media(hover,601){
        height: 50px;
        width: 200px;
      }
      @include media(screen,600){
        height: 30px;
        width: 115px;
      }
      img {
        display: block;
        height: 100%;
        width: auto;
      }
    }
    &--nav {
      padding: 0 $padding_md;
      @include media(screen,1200){
        display: none;
      }
      nav {
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        line-height: 1.2;
        @include media(hover,1601){
          font-size: 3.5rem;
        }
        @include media(screen,1600){
          font-size: 3.4rem;
        }
      }
      ul {
        display: flex;
        align-items: stretch;
      }
      li {
        display: flex;
        align-items: center;
        position: relative;
        &:not(:last-child){
          @include media(hover,1601){
            margin-right: 2*$padding_md;
          }
          @include media(screen,1600){
            margin-right: 2*$padding_sm;
          }
          &:after {
            content: "";
            left: 100%;
            width: 1px;
            top: 0;
            bottom: 0;
            height: $padding_sm;
            background-color: $text_lt;
            margin: auto;
            position: absolute;
            @include media(hover,1601){
              margin-left: $padding_md;
            }
            @include media(screen,1600){
              margin-left: $padding_sm;
            }
          }
        }
        a {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          position: relative;
          &:after {
            content: "";
            height: 3px;
            left: 0;
            right: 0;
            top: 100%;
            background-color: $link;
            position: absolute;
            margin-top: 5px;
            transition: all 0.3s;
            opacity: 0;
            transform: translateY(100%);
          }
          &.active {
            &:after {
              opacity: 1;
              transform: translateY(0);
            }
          }
          @include hover(){
            &:after {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
    &--drop {
      margin-top: 0;
      transition: all 0.2s;
      pointer-events: none;
      opacity: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      background-color: $white;
      box-shadow: 0 10px 20px 0 rgba(26,26,26,0.2);
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      padding: 10px $padding_sm;
      transform: translateX(-50%);
      &:before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 10px;
      }
      a {
        padding: 8px;
        color: $text;
        &.active {
          color: $link_dark;
        }
        @include hover(){
          color: $link_dark;
        }
      }
    }
    &--stats {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include media(hover,1201){
        width: 200px;
      }
      @include media(screen,600){

      }
      .stats {
        font-size: 3.5rem;
        display: flex;
        align-items: center;
        @include hover(){
          color: $link_dark;
        }
        @include media(screen,600){
          position: relative;
          flex-direction: column;
          padding: 0 12px;
          margin-left: 1px;
          &:after {
            content: "";
            left: 100%;
            width: 1px;
            top: 0;
            bottom: 0;
            height: 20px;
            background-color: #b2b9c1;
            margin: auto;
            position: absolute;
          }
          &:first-child {
            &:before {
              content: "";
              right: 100%;
              width: 1px;
              top: 0;
              bottom: 0;
              height: 20px;
              background-color: #b2b9c1;
              margin: auto;
              position: absolute;
            }
          }
        }
      }
      .stats-enter {
        @include media(screen,1200){
          display: none;
        }
      }
      .stats-profile {
        cursor: default;
        position: relative;
        @include media(hover,1201){
          margin-right: $padding_sm;
          margin-left: $padding_sm;
        }
        @include media(screen,1200){
          display: none;
        }
      }
      .stats-prods {
        @include media(hover,601){margin-left: $padding_sm;}
      }
      @include media(hover,601){
        .icon-likes {
          margin-left: 15px;
        }
        .icon-basket {
          margin-left: 15px;
        }
      }

    }
    .has-drop {
      @include hover(){
        .header-main--drop {
          opacity: 1;
          pointer-events: auto;
          margin-top: 10px;
        }
      }
    }
  }
  &-mobile {
    @include media(screen,1200){
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      pointer-events: none;
      transition: all 0.35s;
      .is-open & {
        opacity: 1;
        pointer-events: auto;
      }
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($text,0.8);
      }
      .search {
        padding: 10px 0;
        .fieldset {
          width: 100%;
          display: flex;
          border: 1px solid $border;
          border-radius: 40px;
        }
        .input {
          border: none;
          background: none;
          padding: 0 15px;
          line-height: 30px;
        }
        button {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          background: none;
          border: none;
          padding: 7px;
          margin-right: 5px;
        }
      }
      &--nav {
        position: absolute;
        background-color: $white;
        right: 0;
        top: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: all 0.4s;
        line-height: 1.2;
        font-size: 4rem;
        font-weight: 600;
        overflow: auto;
        .is-open & {
          transform: translateX(0);
        }
        @include media(hover,601){
          padding: $padding_sm 2*$padding_sm;
          width: 50%;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 100%;
            bottom: 0;
            width: 10px;
            background: rgba($border,0.16);
            background: -moz-linear-gradient(left, rgba($border,0) 0%, rgba($border,0.16) 100%);
            background: -webkit-linear-gradient(left, rgba($border,0) 0%,rgba($border,0.16) 100%);
            background: linear-gradient(to right, rgba($border,0) 0%,rgba($border,0.16) 100%);
          }
        }
        @include media(screen,600){
          padding: $padding_sm;
          left: 0;
        }
        li {
          padding: 10px 0;
          ul {
            padding-top: 10px;
          }
          & > span {
            text-transform: uppercase;
          }
          .show-more {
            display: flex;
            align-items: center;
            .tick {
              width: 20px;
              height: 20px;
              flex-shrink: 0;
              position: relative;
              margin-left: 15px;
              &:after {
                content: "";
                position: absolute;
                width: 9px;
                height: 9px;
                border-color: $text_lt;
                border-width: 2px;
                border-style: solid solid none none;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                transform: rotate(135deg) translate(-2px,2px);
                transition: all 0.3s;
              }
            }
          }
          &.show {
            .show-more {
              .tick {
                &:after {
                  transform: rotate(315deg) translate(-2px,2px);
                }
              }
            }
          }
        }
        .profile-menu {
          margin-top: 30px;
        }
      }
    }
  }
  &.minimized {
    @include media(hover,1201){
      top: -40px;
    }
    .header-main--wrap {
      height: 60px;
    }
  }

}

@include media(screen,1200){
  .burger {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;
    @include media(hover,601){
      margin-left: 40px;
    }
    @include media(screen,600){
      margin-left: 15px;
    }
    span {
      height: 4px;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: $text;
      border-radius: 3px;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:first-child {
        .is-open & {
          transform: rotate(45deg);
        }
      }
      &:last-child {
        .is-open & {
          transform: rotate(-45deg);
        }
      }
    }
    &:before,
    &:after {
      content: "";
      left: 0;
      right: 0;
      height: 4px;
      width: 100%;
      position: absolute;
      background-color: $text;
      transition: .25s ease-in-out;
      border-radius: 3px;
    }
    &:before {
      top: 4px;
      .is-open & {
        top: 18px;
        opacity: 0;
        transform: scale(0.4);
      }
    }
    &:after {
      bottom: 4px;
      .is-open & {
        bottom: 18px;
        opacity: 0;
        transform: scale(0.4);
      }
    }
  }
}
