@import "input-text";
@import "textarea";
@import "file";
@import "check";
@import "checked";
@import "range";
@import "counter";
@import "submit";
@import "form-text";
@import "radio-flex";

.invalid {
  .input, .textarea {
    box-shadow: 0 0 4px 1px rgba(255,128,125,1) inset;
  }
  .check {
    label>span:before {
      border-color: rgba(255,128,125,1);
    }
  }
}
.invalids {
  color: rgba(255,128,124,.5);
  input, textarea {background-color: rgba(255,128,125,1)}
  display: flex;
}

.form-row {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  @include media(hover,1025){
    margin: 0 -1*$padding_sm;
  }
  @include media(screen,1024){
    margin: 0 -10px;
  }
  &.col-lg-2 {
    @include media(hover,1201){
      flex-wrap: wrap;
      .form-row-element {
        width: 100%;
        max-width: 50%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    @include breakpoints(1025,1200){
      flex-wrap: wrap;
      .form-row-element {
        width: 100%;
        max-width: 25%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    @include breakpoints(768,1024){
      flex-wrap: wrap;
      .form-row-element {
        width: 100%;
        max-width: 25%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    @include media(screen,767){
      flex-wrap: wrap;
      .form-row-element {
        width: 100%;
        max-width: 50%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  &.col-lg-3 {
    @include media(hover,1201){
      flex-wrap: wrap;
      .form-row-element {
        width: 100%;
        max-width: 33.3333%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    @include breakpoints(1025,1200){
      flex-wrap: wrap;
      .form-row-element {
        width: 100%;
        max-width: 20%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    @include breakpoints(768,1024){
      flex-wrap: wrap;
      .form-row-element {
        width: 100%;
        max-width: 20%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    @include media(screen,767){
      flex-wrap: wrap;
      .form-row-element {
        width: 100%;
        max-width: 50%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  &.centered {
    align-items: center;
  }
  &.stretched {
    align-items: stretch;
  }
  &.nowrap-tablet {
    @include breakpoints(601,1024){
      flex-wrap: nowrap;
      .form-row-col.col-66 {
        .form-row-col.col-50 {
          max-width: none;
        }
      }
    }
  }
  &-col {
    width: 100%;
    @include media(hover,1025){
      padding: 0 $padding_sm;
    }
    @include media(screen,1024){
      padding: 0 10px;
    }
    &.col-66 {
      @include media(hover,1025){
        max-width: 66.6666%;
      }
    }
    &.col-50 {
      @include media(hover,601){
        max-width: 50%;
      }
    }
    &.col-33 {
      @include media(hover,1025){
        max-width: 33.3333%;
      }
    }
  }
  &-element {
    .custom-form & {
      padding: 0.5*$padding_sm 0;
    }
    .contact-form--submit &,
    .filter-form & {
      padding: 0.5*$padding_sm 0 0;
      @include media(hover,601){
        &:last-child {
          padding-bottom: 0.5*$padding_sm;
        }
      }
    }
    &.to-stretch {
      height: 100%;
      * {
        height: 100%;
      }
    }
    &.has-label {
      display: flex;
      align-items: center;
      @include media(screen,767){
        flex-direction: column;
        align-items: stretch;
      }
      .label {
        flex-shrink: 0;
        font-size: 3.5rem;
        @include media(hover,1025){
          width: 190px;
          padding-right: 2*$padding_sm;
        }
        @include breakpoints(768,1024){
          width: 150px;
          padding-right: $padding_sm;
        }
        @include media(screen,767){
          margin-bottom: 5px;
        }
      }
      input {
        color: $text;
      }
    }
  }
}

.form-title {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 600;
  color: $border;
  padding: 10px 0;
  &:not(:first-child){
    margin-top: $padding_sm;
  }
}