.textarea {
  display: block;
  width: 100%;
  position: relative;
  .custom-form & {
    background-color: $bg;
  }
  textarea {
    display: block;
    width: 100%;
    position: relative;
    resize: none;
    .custom-form & {
      background-color: transparent;
      line-height: 1.3;
      color: $border;
      font-size: 3.5rem;
      padding: 10px 20px;
      border: none;
      outline: none;
    }
  }
  .custom-form & {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 5px;
      background: transparent;
      background: -moz-linear-gradient(top, rgba($text_lt,0.5) 0%, rgba($text_lt,0) 100%);
      background: -webkit-linear-gradient(top, rgba($text_lt,0.5) 0%, rgba($text_lt,0) 100%);
      background: linear-gradient(to bottom, rgba($text_lt,0.5) 0%, rgba($text_lt,0) 100%);
    }
  }
}

