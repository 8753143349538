.input {
  display: block;
  width: 100%;
  position: relative;
  .custom-form & {
    background-color: $bg;
  }
  input {
    display: block;
    width: 100%;
    position: relative;
    .custom-form & {
      background-color: transparent;
      height: 40px;
      line-height: 40px;
      color: $border;
      font-size: 3.5rem;
      padding: 0 20px;
      border: none;
      outline: none;
    }
  }
  select {
    display: block;
    width: 100%;
    position: relative;
    appearance: none;
    .custom-form & {
      background-color: transparent;
      height: 40px;
      line-height: 40px;
      color: $border;
      font-size: 3.5rem;
      padding: 0 20px;
      border: none;
      outline: none;
    }
  }
  .error-text {
    position: absolute;
    top: 100%;
    left: 0;
    color: rgba(255,128,124,1);
    font-size: 12px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .custom-form & {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 5px;
      background: transparent;
      background: -moz-linear-gradient(top, rgba($text_lt,0.5) 0%, rgba($text_lt,0) 100%);
      background: -webkit-linear-gradient(top, rgba($text_lt,0.5) 0%, rgba($text_lt,0) 100%);
      background: linear-gradient(to bottom, rgba($text_lt,0.5) 0%, rgba($text_lt,0) 100%);
    }
  }
}

