@include media(hover,1201){
  .fixed-scrollbar {
    position: relative;
    height: 100%;
    &.fixed {
      transition: all .2s;
      padding-top: 40px;
      .fixing {
        position: fixed;
        .col-sidebar & {
          width: 380px;
        }
        .col-projects & {
          width: 190px;
        }
      }
    }
    &.bottom {
      transition: all .2s;
      .fixing {
        position: absolute;
        bottom: 0;
        left: 0 !important;
        top: auto !important;
      }
    }
    .modal-bg {display: none;}
    .fixing {
      width: 100%;
      display: block !important;
      .col-sidebar & {
        //height: 65vh;
        .partner-page--text & {
          height: auto;
        }
      }
    }
  }
}
@include media(screen,1200){
  .modal-filter {
    transition: all 0.2s;
    &.active {
      .fixing {
        display: block;
      }
    }
  }
  .fixing {
    padding-top: 20px;
    display: none;
  }
  .catalog-filters {

  }
}