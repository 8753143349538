.check {
  display: flex;
  width: 100%;
  position: relative;
  color: $border;
  label {
    display: flex;
    align-items: flex-start;
    font-size: 3.5rem;
    line-height: 16px;
    position: relative;
    cursor: pointer;
    user-select: none;
    .add-service--header & {
      text-transform: uppercase;
      color: $text;

      font-weight: 600;
      @include media(hover,601){font-size: 4rem;}
      @include media(screen,600){font-size: 3.5rem;}
    }
    & > span {
      position: relative;
      align-items: center;
      padding: 2px 0 2px 30px;
      .ordering-pays & {
        display: flex;
      }
      .ico {
        margin-left: 5px;
        img {
          display: block;
          max-height: 30px;
          height: auto;
          width: auto;
        }
      }
      .add-service--header & {
        padding-left: 40px;
      }
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        border-width: 1px;
        border-style: solid;
        position: absolute;
        left: 0;
        top: 0;

        box-sizing: border-box;
        background-color: $white;
        .custom-form & {
          border-color: #dbe6f6;
        }
        .filter-form & {
          border-color: $text_lt;
        }
      }
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        background-color: $link;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%203.12%202.36%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3B%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Echeck%3C/title%3E%3Cg%20id%3D%22Layer_2%22%20data-name%3D%22Layer%202%22%3E%3Cg%20id%3D%22Layer_1-2%22%20data-name%3D%22Layer%201%22%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22M.4%2C1l.65.65L2.73%2C0l.4.4L1.25%2C2.27a.27.27%2C0%2C0%2C1-.4%2C0L0%2C1.42Z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px auto;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
      &.centered {
        &:before {
          bottom: 0;
          margin: auto;
        }
        &:after {
          bottom: 0;
          margin: auto;
        }
      }
      a {
        margin: 0 5px;
        text-decoration: underline;
      }
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      &:checked {
        & ~ span {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

