.referral {
  &-box {
    margin-bottom: $padding_md;
  }
  &-sections {
    display: flex;
    @include media(hover,1025){
      margin: 0 -0.5*$padding_md;
    }
    @include media(screen,1024){
      flex-direction: column;
    }
    .section {
      width: 100%;
      @include media(hover,1025){
        padding: 0 0.5*$padding_md;
      }
      @include media(screen,1024){
        &:not(:last-child){
          margin-bottom: $padding_sm;
        }
      }
    }
  }
  &-item {
    background-color: $white;
    color: $border;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    @include media(hover,601){
      padding: 30px 24px;
    }
    @include media(screen,600){
      padding: $padding_sm;
    }
    .section-title {
      font-size: 4.25rem;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .form-text {
      line-height: 1.6;
      font-size: 3.35rem;
      margin-bottom: 20px;
    }
    .link {
      width: 100%;
      display: flex;
      margin-bottom: 20px;
      position: relative;
      .custom-form {
        width: 100%;
      }
      .copy-btn {
        flex-shrink: 0;
      }
    }
    .link-info {
      font-size: 2.95rem;
      a {
        color: inherit;
        background: linear-gradient(to bottom, $border 0%, $border 100%);
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 1px 1px;
        padding: 0 1px;
        &:hover {
          @include media(hover,1025){
            color: $white;
            background-size: 1px 100%;
          }
        }
      }
    }
  }
}


.ref-link {
  position: fixed;
  box-shadow: 0 10px 20px 0 rgba($black,0.6);
  bottom: 10px;
  right: 10px;
  z-index: 999;
  background-color: $white;
  border: 8px solid $hash;
  padding: 30px 15px 15px;
  color: $border;
  transition: all 0.3s;
  &.hidden {
    transform: translateY(calc(100% + 10px));
    .ref-link--toggle {
      transform: translateY(calc(-100% - 8px));
      background-color: $hash;
      color: $white;
      box-shadow: 0 10px 20px 0 rgba($black,0.6);
      &:after {
        content: attr(data-toggle-show);
      }
    }
  }
  .link {
    width: 100%;
    position: relative;
    display: flex;
    .custom-form {
      width: 100%;
    }
    .copy-btn {
      flex-shrink: 0;
    }

  }
  &--toggle {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    color: $link_dark;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    padding: 8px 12px;
    border-radius: 4px 4px 0 0;
    &:after {
      content: attr(data-toggle-hide);
    }
  }
  &--title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  &--subtitle {
    font-size: 14px;
    margin-bottom: 20px;
    a {
      color: inherit;
      background: linear-gradient(to bottom, $border 0%, $border 100%);
      background-position: 0 100%;
      background-repeat: repeat-x;
      background-size: 1px 1px;
      padding: 0 1px;
      &:hover {
        @include media(hover,1025){
          color: $white;
          background-size: 1px 100%;
        }
      }
    }
  }
}

.referral-item .link,
.ref-link .link {
  &:after,
  &:before {
    opacity: 0;
    pointer-events: none;
    transition: all 3s;
  }
  &:after {
    content: 'ССЫЛКА СКОПИРОВАНА';
    position: absolute;
    left: 50%;
    bottom: 100%;
    background-color: #249A56;
    color: $white;
    text-transform: uppercase;
    transform: translate(-50%,-8px);
    padding: 3px 11px;
    font-size: 14px;
    border-radius: 4px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid solid none;
    border-width:  4px;
    border-color: #249A56 transparent;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%,-4px);
  }
  &.copied {
    &:after,
    &:before {
      opacity: 1;
      pointer-events: auto;
      transition: all 0.3s;
    }
  }
}