.project-page {
  .tab-main {
    &-slider {
      .owl-prev,
      .owl-next {
        top: 50%;
      }
      .plan-item {
        cursor: zoom-in;
      }
      .gallery-items {
        display: none;
        pointer-events: none;
      }
    }
    &-table {
      font-size: 3.5rem;
      color: $black;
      span {
        display: inline-block;
        margin-right: 10px;
      }
      table {
        border-collapse: collapse;
        width: 100%;
      }
      td {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      tr {
        border-bottom: 1px solid $text_lt;
      }
      .flex {
        display: flex;
        align-items: center;
      }
      .name {
        width: 40%;
        padding-right: $padding_sm;
        color: $border;
      }
      .icon-sign {
        margin-top: 2px;
        margin-left: 5px;
        flex-shrink: 0;
      }
    }
  }
}