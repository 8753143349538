/***********************************************/
/*                compare table                */
/***********************************************/

.compare {
  &-page {
    background-color: $bg;
    @include media(hover,1601){
      padding: 2*$padding_sm 0 2*$padding_md 0;
    }
    @include media(screen,1600){
      padding: 2*$padding_sm 0;
    }
    .title {
      margin-bottom: $padding_md;
    }
    .custom-row {
      @include media(screen,1600){
        display: block;
        margin: 0;
        .custom-row-col.col-elastic {
          max-width: none;
          padding: 0;
        }
        .custom-row-col.col-sidebar {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
  &-table {
    .profile-orders & {
      background-color: $white;
      position: relative;
      &:not(:last-child){
        @include media(hover,768){
          margin-bottom: 50px;
        }
        @include media(screen,767){
          margin-bottom: 30px;
        }
      }
    }
    &--header {
      white-space: nowrap;
      display: flex;
      width: 100%;
      background-color: $text;
      margin-bottom: $padding_sm;
      @include media(hover,1025){padding: 5px 0;}
      @include breakpoints(768,1024){padding: 5px 10px;}
      @include media(screen,1024){
        display: none;
      }
      .part-cost,
      .part-total-cost,
      .part-project {
        @include media(hover,768){
          justify-content: center;
        }
      }
      .part-project {
        @include media(screen,1024){
          display: none;
        }
      }
      .compare-table--part {
        @include media(screen,1024){
          padding: 0 10px;
        }
        @include media(screen,767){
          &:last-child {
            a {
              border: none;
            }
          }
        }
      }
      a {
        color: $text_lt;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        height: 30px;
        .arrow {
          width: 30px;
          height: 30px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 9px;
            height: 9px;
            border-color: currentColor;
            border-width: 2px;
            border-style: solid solid none none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(135deg) translate(-2px,2px);
            transition: transform 0.3s;
          }
        }
        &.down {

        }
        &.up {
          .arrow {
            &:after {
              transform: rotate(315deg) translate(-2px,2px);
            }
          }
        }
        @include hover(){
          color: $white;
        }
        @include media(screen,767){
          width: 100%;
          justify-content: space-between;
          border-bottom: 1px solid $border;
        }
      }
      @include breakpoints(1201,1366){
        font-size: 3.5rem;
      }
      @include breakpoints(768,1200){
        font-size: 3rem;
      }
      @include media(screen,767){
        flex-direction: column;
        font-size: 3.5rem;
      }
    }
    &--total {
      //white-space: nowrap;
      display: flex;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid $text_lt;
      .part-cost,
      .part-total-cost,
      .part-project {
        @include media(hover,768){
          justify-content: center;
        }
      }
      .part-project {
        @include media(screen,1024){
          display: none;
        }
      }
      .compare-table--part {
        @include media(screen,1024){
          padding: 0 10px;
        }
        @include media(screen,767){
          &:last-child {
            a {
              border: none;
            }
          }
        }
      }
      dl {
        font-size: 3.5rem;
        line-height: 1.5;
        @include media(screen,767){
          padding: 5px 0;
          text-align: center;
        }
        dt {
          font-weight: 700;
        }
        .sended {
          color: #009788;
        }
      }
      a {
        color: $text_lt;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        height: 30px;
        .arrow {
          width: 30px;
          height: 30px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 9px;
            height: 9px;
            border-color: currentColor;
            border-width: 2px;
            border-style: solid solid none none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(135deg) translate(-2px,2px);
            transition: transform 0.3s;
          }
        }
        &.down {

        }
        &.up {
          .arrow {
            &:after {
              transform: rotate(315deg) translate(-2px,2px);
            }
          }
        }
        @include hover(){
          color: $white;
        }
        @include media(screen,767){
          width: 100%;
          justify-content: space-between;
          border-bottom: 1px solid $border;
        }
      }
      @include breakpoints(1201,1366){
        font-size: 3.5rem;
      }
      @include breakpoints(768,1200){
        font-size: 3rem;
      }
      @include breakpoints(768,1024){
        padding: 0 10px;
      }
      @include media(hover,768){
        height: 80px;
      }
      @include media(screen,767){
        flex-direction: column;
        font-size: 3.5rem;
        padding: 10px;
      }
    }
    &--main {
      user-select: none;
      @include media(hover,1025){
        .part-total-count,
        .part-count {
          justify-content: center;
        }
      }
      @include media(screen,1024){
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
      }
      @include breakpoints(601,1024){
        .profile-orders & {
          margin: 0;
          padding: 20px 10px;
        }
      }
    }
    &--addition {
      display: flex;
      width: 100%;
      align-items: center;
      min-height: 80px;
      border-top: 1px solid $text_lt;
      padding: 10px $padding_sm;
      font-size: 3.5rem;
      line-height: 1.5;
    }
    &--item {
      width: 100%;
      @include media(hover,1025){
        &:not(:last-child){
          margin-bottom: $padding_sm;
          .profile-orders & {
            margin-bottom: 0;
            border-bottom: 1px solid $text_lt;
          }
        }
      }
      @include media(screen,1024){
        padding: 0 10px;
        margin-bottom: $padding_sm;
      }
      @include breakpoints(601,1024){
        max-width: 50%;
      }
    }
    &--section {
      position: relative;
      display: flex;
      width: 100%;
      background-color: $white;
      transition: all 0.3s;
      @include hover(){
        box-shadow: 0 0 40px 0 rgba($text,0.2);
        z-index: 1;
        .compare-table--remove {
          opacity: 1;
        }
      }
      @include media(screen,1024){
        flex-direction: column;
        height: 100%;
      }
    }
    &--part {
      display: flex;
      align-items: center;
      @include media(hover,1201){
        padding: 0 $padding_sm;
      }
      @include breakpoints(1025,1200){
        padding: 0 10px;
      }
      @include media(hover,1025){
        width: 100%;
        &.part-total {
          width: 16.5%;
        }
      }
      .compare-table--main & {
        @include breakpoints(768,1024){
          align-items: flex-start;
          padding: 0 $padding_sm;
          margin-bottom: $padding_sm;
        }
        @include media(screen,767){
          flex-direction: column;
          padding: 0 $padding_sm;
          margin-bottom: $padding_sm;
          align-items: flex-start;
        }
      }
      &.part-project {
        @include media(hover,1025){
          width: 200px;
          flex-shrink: 0;
          padding: 0 $padding_sm 0 0;
        }
        @include media(screen,1024){
          margin: 0 -20px $padding_sm;
        }
      }
      &.part-number {
        @include media(hover,1025){
          width: 200px;
          flex-shrink: 0;
          padding: 0 $padding_sm 0 $padding_md;
        }
      }
      &.part-count {
        @include media(hover,1025) {
          min-width: 245px;
        }
      }
      &.part-total-count {
        @include media(hover,1201) {
          min-width: 245px;
        }
        @include breakpoints(1025,1200){
          min-width: 180px;
        }
      }
      &.part-total-cost {
        @include breakpoints(1025,1366) {
          width: 80%;
        }
      }
      @include media(hover,1025){
        .compare-page & {
          &:not(.part-project){
            width: calc(20% - 40px);
            max-width: calc(20% - 40px);
          }
        }
        .basket-order & {
          &:not(.part-project){
            width: calc(25% - 111px);
            max-width: calc(25% - 111px);
          }
        }
      }
      .btns {
        margin: $padding_sm 0 0;
        @include media(screen,1024){
          width: 100%;
          display: flex;
          align-items: center;

        }
      }
      .no-form-element {
        pointer-events: none;
        @include media(hover,601){
          padding: 10px 0;
        }
        @include media(screen,600){
          padding-top: 10px;
        }
      }
    }
    &--remove {
      cursor: pointer;
      width: 18px;
      height: 19px;
      border: none;
      background-color: transparent;
      color: $link_dark;
      transition: all 0.3s;
      @include media(hover,1025){
        opacity: 0;
        position: absolute;
        top: 6px;
        right: 6px;
      }
      @include media(screen,1024){
        margin-left: auto;
      }
    }
    .placeholder {
      font-size: 4rem;
      line-height: 1.6;
      @include media(hover,1025){
        width: 50%;
      }
      @include media(screen,1024){
        padding: 0 10px;
      }
    }
  }
  &-sidebar {
    @include media(screen,1600){
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @include media(hover,1025){
      padding: 2*$padding_sm 0;
    }
    @include media(screen,1024){
      padding: 2*$padding_sm 0 0;
    }
    .order {
      display: flex;
      @include media(hover,1601){
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: $padding_md;
      }
      @include media(screen,1600){
        align-items: center;
      }
      @include media(screen,1024){
        width: 100%;
        justify-content: space-between;
        margin-bottom: $padding_md;
      }
      @include media(screen,600){
        flex-direction: column;
      }
      .phone {
        display: flex;
        align-items: center;

        font-weight: 600;
        white-space: nowrap;
        line-height: 1;
        @include media(hover,1601){
          margin-bottom: $padding_sm;
          font-size: 8.5rem;
        }
        @include breakpoints(601,1600){
          margin-right: 2*$padding_sm;
          font-size: 8.5rem;
        }
        @include media(screen,600){
          margin-bottom: $padding_sm;
          font-size: 8rem;
        }
        .icon {
          margin-right: $padding_sm;
        }
      }
    }
    &--info {
      background:$hash;
      font-size: 3.5rem;
      line-height: 1.6;
      padding: $padding_sm $padding_md;
      background: linear-gradient(to right bottom, $hash 50%, $hash_lt 50%);
      color: $white;
      @include media(hover,1601){
        margin-bottom: $padding_md;
      }
      @include media(screen,1600){
        width: 420px;
      }
      @include breakpoints(1025,1600){
        margin-left: 2*$padding_sm;
      }
      @include media(screen,1024){
        margin: 0 auto;
      }
      .info-title {
        font-weight: 800;
        margin-bottom: 5px;
      }
    }
  }
  &-list {
    @include media(screen,1600){
      margin-top: 2*$padding_sm;
    }
    ul {
      @include media(screen,1600){
        width: 100%;
        display: flex;
      }
      @include media(screen,1024){
        flex-wrap: wrap;
      }
    }
    li {
      display: flex;
      align-items: flex-start;
      @include media(hover,1601){
        margin-bottom: 15px;
      }
      @include breakpoints(1025,1600){
        &:not(:last-child){
          margin-right: $padding_md;
        }
      }
      @include breakpoints(601,1024){
        padding: 0 $padding_sm;
        max-width: 50%;
        margin-bottom: $padding_sm;
      }
      @include media(screen,600){
        margin-bottom: $padding_sm;
      }
    }
    .icon {
      flex-shrink: 0;
      color: $border;
      margin-right: $padding_sm;
      &-delivery {
        margin-top: 4.5px;
      }
      &-forrussia {
        margin-top: 2px;
      }
    }
    &--text {
      font-size: 3.5rem;
      line-height: 1.5;
    }
    &--title {
      font-weight: 800;
    }
  }
}
