.partner-page {

  &--wrap {
    position: relative;
    padding-top: 2*$padding_sm;
    background-color: $bg;
    @extend %cover;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($text,0.5);
    }
    .title {
      text-align: center;
      color: $white;
      margin-bottom: 2*$padding_sm;
      position: relative;
    }
  }

  &--slider {
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      bottom: 0;
      left: -500px;
      right: -500px;
      background-color: $bg;
      max-width: 1800px;
      margin: auto;
    }
    .owl-prev,
    .owl-next {
      top: 50%;
    }
    .owl-dots {
      left: 0;
      right: 0;
      position: absolute;
      bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .owl-dot {
      width: 6px;
      height: 6px;
      border-radius: 20px;
      margin: 0 10px;
      border: none;
      background-color: $black;
      &.active {
        transform: scale(2);
      }
    }
  }
  &--info {
    background-color: $bg;
    position: relative;
    margin-bottom: 2*$padding_sm;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 50%;
      background-color: $white;
    }
    &-table {
      position: relative;
      background-color: $white;
      box-shadow: 0 0 10px 5px rgba($border,0.16);
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      @include media(hover,1025){
        padding: $padding_md;
      }
      @include media(screen,1024){
        padding: $padding_sm;
        flex-wrap: wrap;
      }
      .cell {
        font-size: 3.5rem;
        line-height: 1.6;
        @include media(hover,601){
          padding: 10px $padding_sm;
        }
        @include media(screen,600){
          padding: 10px 0;
        }
        a {
          color: $link_hover;
          @extend %hover_underline;
        }
        &-logo {
          flex-shrink: 0;
          text-align: center;
          @include media(hover,1201){
            width: 180px;
          }
          @include breakpoints(1025,1200){
            width: 120px;
          }
          @include breakpoints(601,1024){
            width: 30%;
            margin-bottom: 10px;
          }
          @include media(screen,600){
            width: 100%;
            margin-bottom: 10px;
          }
          a {
            display: inline-block;
            img {
              display: block;
              @include media(hover,1201){
                max-width: 180px;
                max-height: 100px;
              }
              @include media(screen,1200){
                max-width: 100px;
                max-height: 100px;
              }
            }
          }
        }
        &-location {
          @include breakpoints(601,1024){
            width: 70%;
          }
          @include media(screen,600){
            width: 100%;
          }
        }
        &-contacts {
          white-space: nowrap;
          @include media(screen,600){
            width: 100%;
          }
        }
      }
    }
  }
  &--text {
    margin-bottom: 2*$padding_md;
    .catalog-actions {
      @include media(hover,1025){
        max-width: 85%;
      }
    }
  }
}
