.footer {
  background-color: $text;
  color: $text_lt;
  @include media(hover,1201){
    padding: 2*$padding_sm 0 3*$padding_md;
  }
  @include breakpoints(601,1200){
    padding: 2*$padding_sm 0;
  }
  @include media(screen,600){
    padding: $padding_md 0 25px;
  }
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(hover,1025){
      padding: 0.5*$padding_sm 0;
      margin-bottom: 2*$padding_md;
    }
    @include media(screen,1024){
      flex-direction: column;
      margin-bottom: 2*$padding_sm;
    }
    .sharing {
      @include breakpoints(768,1024){
        align-self: flex-start;
      }
      @include media(screen,767){
        margin-top: $padding_md;
        display: flex;
      }
    }
    &--order {
      display: flex;
      align-items: center;
      @include media(hover,1025){
        margin-right: $padding_sm;
      }
      @include media(screen,1024){
        width: 100%;
        justify-content: space-between;
      }
      @include breakpoints(768,1024){
        margin-bottom: $padding_sm;
      }
      @include media(screen,600){
        flex-direction: column;
      }
      .order {
        color: $white;
        font-weight: 600;
        @include media(hover,1025){
          font-size: 8.5rem;
          margin-right: 2*$padding_md;
        }
        @include breakpoints(768,1024){
          font-size: 6.5rem;
          margin-right: 2*$padding_md;
        }
        @include breakpoints(601,767){
          margin-right: $padding_md;
          font-size: 5rem;
        }
        @include media(screen,600){
          margin-bottom: $padding_md;
          font-size: 4.5rem;
        }
        a {
          white-space: nowrap;
        }
      }
    }
  }
  &-main {
    @include media(hover,768){
      display: flex;
      justify-content: space-between;
      margin-bottom: 2*$padding_sm;
    }
    @include media(screen,767){
      display: none;
    }
    &--nav {
      display: flex;
      align-items: flex-start;
      width: 100%;
      flex-shrink: 500;
      line-height: 1.2;
      .section {
        width: 25%;
        @include media(hover,1025){
          margin-right: 2*$padding_sm;
        }
        @include media(screen,1024){
          &:not(:last-child){
            margin-right: $padding_md;
          }
        }
        &.columns {
          width: 50%;
          ul {
            columns: 2 auto;
            column-gap: 2*$padding_sm;
          }
          li {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
          }
        }
        li {
          margin-bottom: 0.5*$padding_sm;
        }
        a {
          font-size: 3rem;
          @include hover(){
            color: $white;
          }
        }
      }
      .title {
        text-transform: uppercase;
        font-size: 3.5rem;
        color: $white;
        border-bottom: 2px solid currentColor;
        margin-bottom: $padding_sm;
        padding: 10px 0;
      }
    }
    &--logo {
      @include media(hover,1025){
        width: 15%;
        display: flex;
        padding-top: 2*$padding_sm;
        justify-content: flex-end;
      }
      @include media(screen,1024){
        display: none;
      }
      a {
        width: 75px;
        height: 85px;
        img {
          @extend %fullBlock;
        }
      }
    }
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(screen,600){
      flex-direction: column;
    }
    &--copyright {
      color: $border;
      font-size: 3rem;
      @include media(screen,600){
        margin-bottom: $padding_sm;
      }
    }
    &--auth {
      color: $white;
      font-size: 3rem;
      font-weight: 500;
      white-space: nowrap;
      @include media(screen,600){
        align-self: flex-end;
      }
    }
  }
}