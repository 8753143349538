.projects {

  &-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bg;
    color: $border;
    padding: $padding_sm;
    min-height: 22.7rem;
    @include media(hover,601){
      margin-bottom: 2*$padding_sm;
    }
    @include media(screen,600){
      flex-direction: column;
      margin-bottom: $padding_sm;
    }
    &--item {
      text-transform: uppercase;
      margin: 0 $padding_sm;
      font-weight: 600;
      text-align: center;
      order: 3;
      background: none;
      border: none;
      &:nth-child(1){
        order: 1;
      }
      &:nth-child(2){
        order: 3;
      }
      &:nth-child(3){
        order: 5;
      }
      &:not(.current) {
        font-size: 4rem;
        border-bottom: 1px dotted currentColor;
        @include hover(){
          border-color: transparent;
        }
      }
      &.current {
        cursor: default;
        pointer-events: none;
        @include media(hover,768){
          font-size: 8.5rem;
        }
        @include breakpoints(601,767){
          font-size: 7rem;
        }
        @include media(screen,600){
          font-size: 4.5rem;
        }
        //&:nth-child(1){
        //  order: 4;
        //}
        //&:nth-child(3){
        //  order: 2;
        //}
      }

    }
  }

  &-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    @include media(hover,1367){
      margin: 0 -1*$padding_sm;
    }
    @include breakpoints(601,1366){
      margin: 0 -10px;
    }
    &.col-4 {
      .projects-item {
        @include media(hover,1201){
          width: 25%;
        }
        @include breakpoints(1025,1200){
          width: 33.3333%;
        }
        @include breakpoints(601,1024){
          width: 50%;
        }
        @include media(screen,600){
          width: 100%;
        }
      }
    }
    &.col-3 {
      .projects-item {
        @include media(hover,1601){
          width: 33.3333%;
        }
        @include breakpoints(601,1600){
          width: 50%;
        }
        @include media(screen,600){
          width: 100%;
        }
      }
    }
    .empty {
      @include media(hover,1367){
        padding: 0 $padding_sm;
        margin-bottom: 2*$padding_sm;
      }
      @include breakpoints(601,1366){
        padding: 0 10px;
        margin-bottom: $padding_sm;
      }
      @include media(screen,600){
        margin-bottom: $padding_sm;
      }
    }
  }

  &-item {
    .catalog &,
    .projects & {
      @include media(hover,1367){
        padding: 0 $padding_sm;
        margin-bottom: 2*$padding_sm;
      }
      @include breakpoints(601,1366){
        padding: 0 10px;
        margin-bottom: $padding_sm;
      }
      @include media(screen,600){
        margin-bottom: $padding_sm;
      }
    }
    .projects & {
      @include media(screen,767){
        &:nth-child(n + 5){
          display: none;
        }
      }
    }
    &--wrap {
      width: 100%;
      display: block;
      transition: all 0.3s;
      @include hover(){
        box-shadow: 0 0 40px 0 rgba($text,0.2);
        .projects-item--preview {
          .data {
            background-color: rgba($link_dark,0.7);
            .index {
              background-color: $link_dark;
            }
          }
        }
      }
    }
    &--preview {
      width: 100%;
      display: block;
      position: relative;
      .object-item & {
        @include media(hover,601){
          padding-top: 50%;
        }
        @include media(screen,600){
          padding-top: 75%;
        }
      }
      .projects & {
        padding-top: 66.6667%;
      }
      .article-page--sidebar &,
      .like-slider &,
      .catalog &,
      .projects-slider & {
        padding-top: 75%;
      }
      .part-project & {
        @include media(hover,1025){
          padding-top: 100%;
        }
        @include media(screen,1024){
          padding-top: 50%;
        }
        @include hover(){
          .look-num {opacity: 0}
          .look-text {opacity: 1}
          .look-alone {opacity: 1}
        }
      }
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: $white;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%0A%20%3Cg%3E%0A%20%20%3Ctitle%3Ebackground%3C/title%3E%0A%20%20%3Crect%20fill%3D%22none%22%20id%3D%22canvas_background%22%20height%3D%22402%22%20width%3D%22582%22%20y%3D%22-1%22%20x%3D%22-1%22/%3E%0A%20%3C/g%3E%0A%20%3Cg%3E%0A%20%20%3Ctitle%3ELayer%201%3C/title%3E%0A%20%20%3Cpath%20fill%3D%22%23f0f1f3%22%20stroke%3D%22null%22%20id%3D%22svg_1%22%20d%3D%22m11.40404%2C5.804286l-0.662531%2C-0.662074l0%2C-1.622516c0%2C-0.251305%20-0.205613%2C-0.456918%20-0.456918%2C-0.456918l-0.456918%2C0c-0.251305%2C0%20-0.456918%2C0.205613%20-0.456918%2C0.456918l0%2C0.252676l-0.913836%2C-0.912922c-0.124739%2C-0.117885%20-0.238968%2C-0.25359%20-0.456918%2C-0.25359s-0.332179%2C0.135705%20-0.456918%2C0.25359l-2.947122%2C2.944837c-0.142558%2C0.148498%20-0.251305%2C0.256788%20-0.251305%2C0.456918c0%2C0.257245%200.197389%2C0.456918%200.456918%2C0.456918l0.456918%2C0l0%2C2.741509c0%2C0.251305%200.205613%2C0.456918%200.456918%2C0.456918l1.370754%2C0l0%2C-2.284591c0%2C-0.251305%200.205613%2C-0.456918%200.456918%2C-0.456918l0.913836%2C0c0.251305%2C0%200.456918%2C0.205613%200.456918%2C0.456918l0%2C2.284591l1.370754%2C0c0.251305%2C0%200.456918%2C-0.205613%200.456918%2C-0.456918l0%2C-2.741509l0.456918%2C0c0.259529%2C0%200.456918%2C-0.199673%200.456918%2C-0.456918c0%2C-0.20013%20-0.108747%2C-0.30842%20-0.251305%2C-0.456918z%22/%3E%0A%20%3C/g%3E%0A%3C/svg%3E');
        @extend %cover;
      }
      .hash {
        position: absolute;
        top: 0;
        right: 15px;
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        @include media(hover,1025){
          .part-project & {
            flex-direction: column;
          }
        }
        span {
          display: flex;
          height: 24px;
          align-items: center;
          justify-content: center;
          padding: 0 $padding_sm;
          &.new {
            color: $white;
            background-color: $hash;
          }
          &.sale {
            color: $white;
            background-color: $border;
          }
          &.free {
            color: $white;
            background-color: $black;
          }
          & + span {
            margin-left: 5px;
            @include media(hover,1025){
              .part-project & {
                margin: 3px 0 0 0;
              }
            }
          }
        }
        .part-project & {
          right: auto;
          left: 0;
        }
      }
      .data {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background-color: rgba($text,0.7);
        color: $white;
        transition: all 0.2s;
        .object-item & {
          @include media(hover,601){
            height: 60px;
          }
          @include media(screen,600){
            flex-direction: column;
          }
        }
        .like-slider &,
        .part-project & {
          height: 60px;
        }
        .index {
          background-color: $text;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          text-align: center;
          transition: all 0.2s;
          white-space: nowrap;
          .object-item & {
            font-size: 4rem;
            @include media(hover,768){
              min-width: 190px;
            }
            @include media(hover,601){
              padding: 0 $padding_sm;
            }
            @include media(screen,600){
              padding: 10px $padding_sm;
            }
          }
          .like-slider &,
          .catalog &,
          .projects & {
            padding: 0 $padding_sm;
            min-width: 60px;
            font-size: 4rem;
          }
          .article-page--sidebar &,
          .projects-slider & {
            padding: 0 15px;
            width: 50%;
            @include media(hover,1601){font-size: 0.88vw;}
            @include breakpoints(1367,1600){font-size: 1.1vw;}
            @include breakpoints(1201,1366){font-size: 1.2vw;}
            @include breakpoints(1025,1200){font-size: 1.5vw;}
            @include breakpoints(768,1024){font-size: 1.8vw;}
            @include breakpoints(601,767){font-size: 2vw;}
            @include media(screen,600){font-size: 3vw;}
          }
        }
        .cost {
          .object-item & {
            color: $white;
            align-self: center;
            @include media(hover,768){
              padding: 0 2*$padding_sm;
            }
            @include breakpoints(601,767){
              padding: 0 $padding_sm;
              font-size: 4rem;
            }
            @include media(screen,600){
              font-size: 3rem;
              padding: 0 10px;
            }
          }
        }
        &-details {
          display: flex;
          align-items: stretch;
        }
      }
      .info {
        display: flex;
        align-items: flex-start;
        text-align: center;
        align-self: center;
        .like-slider &,
        .object-item &,
        .catalog &,
        .projects & {
          font-size: 3rem;
          padding: 13px 0;
        }
        .article-page--sidebar &,
        .projects-slider & {
          width: 50%;
          justify-content: center;
          padding: 8px 0;
          white-space: nowrap;
          @include media(hover,1601){font-size: 0.88vw;}
          @include breakpoints(1367,1600){font-size: 1.1vw;}
          @include breakpoints(1201,1366){font-size: 1.2vw;}
          @include breakpoints(1025,1200){font-size: 1.5vw;}
          @include breakpoints(768,1024){font-size: 1.8vw;}
          @include breakpoints(601,767){font-size: 2vw;}
          @include media(screen,600){font-size: 3vw;}
        }
        li {
          position: relative;
          @include media(hover,1367){
            padding: 0 15px;
          }
          @include media(screen,1366){
            padding: 0 10px;
          }
          &:not(:last-child){
            &:after {
              content: "";
              position: absolute;
              left: 100%;
              top: 0;
              bottom: 0;
              height: 30px;
              width: 1px;
              background-color: $white;
              margin: auto 0 auto -0.5px;
            }
          }
        }
        .head {
          display: block;
        }
      }
      .look {
        &-num {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 8.5rem;
          font-weight: 600;
          transition: all 0.2s;
        }
        &-text {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3rem;
          font-weight: 600;
          text-transform: uppercase;
          transition: all 0.3s;
          opacity: 0;
        }
        &-alone {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3rem;
          font-weight: 600;
          text-transform: uppercase;
          transition: all 0.3s;
          opacity: 0;
        }
      }
    }
    &--actions {
      display: flex;
      align-items: center;
      background-color: $white;
      .part-total-cost &,
      .part-cost & {
        flex-direction: column;
        width: 100%;
      }
      .like-slider &,
      .catalog &,
      .projects & {
        @include media(hover,1367){
          padding: $padding_sm;
        }
        @include media(screen,1366){
          padding: 10px;
        }
      }
      .article-page--sidebar & {
        padding: 15px 18px;
      }
      .projects-slider & {
        padding: 18px;
      }
      .prices {
        display: flex;
        align-items: center;
        @include media(screen,1366){
          flex-direction: column;
        }
      }
      .price {
        font-family: 'PT Sans', sans-serif;
        white-space: nowrap;
        color: $border;
        font-weight: 700;
        position: relative;
        margin-right: $padding_sm;
        @include media(hover,1367){
          font-size: 4rem;
        }
        @include media(screen,1366){
          font-size: 3.5rem;
        }
        &.old {
          font-weight: 500;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: $border;
            margin: auto;
          }
        }
        .part-total-cost &,
        .part-cost & {
          margin: 0;
          color: inherit;
          @include media(screen,767){
            font-size: 5rem;
          }
        }
      }
      .price-box {
        @include media(screen,1024){
          display: flex;
          width: 100%;
        }
        @include media(screen,767){
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .icon-like {
        background: none;
        border: none;
        cursor: pointer;
        color: transparent;
        flex-shrink: 0;
        &:not(:last-child){
          margin-right: $padding_sm;
        }
        &.liked {
          color: $link;
        }
        @include hover(){
          color: $link_dark;
        }
      }
      .basket {
        .part-cost & {

        }
      }
    }
    &--info {
      font-size: 3.5rem;
      line-height: 1.5;
      @include media(screen,1024){
        .album-head {
          display: none;
        }
      }
    }
    &--articul {
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      line-height: 1.6;
      transition: all 0.2s;
      background-color: $text;
      color: $white;
      @include hover(){
        background-color: $link_dark;
      }
      @include media(hover,1201){
        font-size: 8.5rem;
        padding: 0 $padding_sm;
      }
      @include media(screen,1200){
        font-size: 6rem;
        padding: 0 15px;
      }
    }

    &--part-title {
      @include media(hover,1025){
        display: none;
      }
      @include media(screen,1024){
        font-size: 3.5rem;
        font-weight: 700;
        flex-shrink: 0;
        margin-bottom: 10px;
      }
      @include breakpoints(768,1024){
        width: 120px;
        margin-right: 10px;
      }
    }

  }

  &-compilation {
    .add-service--list {
      flex-wrap: wrap;
    }
    .contact-page--partners {
      margin: 0;
    }
  }

}

@include media(hover,768){
  [data-filter-box]{
    &:not(.active){display: none;}
  }
}
