.collaborate {
  &-head {
    margin-bottom: 2*$padding_sm;
    &--main {
      padding: 2*$padding_md 0;
    }
    .title {
      text-align: center;
      color: $white;
      margin-bottom: 2*$padding_sm;
    }
    .subtitle {
      font-style: italic;
      color: $white;
      font-family: 'Merriweather';
      @include media(hover,601){
        font-size: 5.2rem;
      }
      @include media(screen,600){
        font-size: 4.2rem;
      }
      a {
        display: inline-block;
        white-space: nowrap;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 1px;
          left: 0;
          right: 0;
          bottom: 3px;
          background-color: $white;
        }
      }
    }
  }
  &-list {
    .col-sidebar {
      @include media(screen,1200){
        order: 1;
      }
    }
    &--items {
      width: 100%;
      @include media(hover,1201){
        max-width: 85%;
      }
    }
    &--person {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      @include media(hover,1201){
        padding: 0 $padding_sm 2*$padding_sm;
        &:before {
          content: "";
          top: 120px;
          bottom: 0;
          right: 50%;
          width: 200%;
          background-color: $hash;
          position: absolute;
        }
      }
      @include media(screen,1200){
        padding: 0 0 2*$padding_sm;
      }

      & > * {
        position: relative;
        z-index: 1;
      }
      .photo {
        width: 240px;
        height: 240px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: $padding_sm;
        img {
          @extend %fullBlock;
          border-radius: 50%;
        }
      }
      .post {
        width: 50%;
        font-size: 3.5rem;
        font-weight: 600;
        line-height: 1.6;
        @include media(hover,1201){
          margin-left: 50%;
          padding-left: 10px;
        }
        @include media(screen,1200){
          margin: 0 auto;
          text-align: center;
        }
      }
    }
    .item {
      @include media(hover,601){
        &:not(:last-child){
          margin-bottom: 2*$padding_md;
        }
      }
      @include media(screen,600){
        &:not(:last-child) {
          margin-bottom: 2*$padding_sm;
        }
      }
      figure {
        width: 100%;
        position: relative;
        margin-bottom: $padding_sm;
        img {
          max-width: 100%;
          display: block;
        }
        .num {
          position: absolute;

          left: 0;
          bottom: 0;
          color: $white;
          font-weight: 700;
          @include media(hover,768){
            font-size: 81rem;
            line-height: 63rem;
          }
          @include breakpoints(601,767){
            font-size: 60rem;
            line-height: 46rem;
          }
          @include media(screen,600){
            font-size: 32rem;
            line-height: 25rem;
          }
        }
      }
      &-text {
        font-size: 4rem;
        line-height: 1.6;
        margin-bottom: $padding_md;
        @include media(hover,1201){
          width: 80%;
        }
      }
      &-action {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include media(hover,1201){
          width: 80%;
        }
        .btn-add {
          @include media(screen,767){
            margin: 3px 0;
          }
        }
      }
      .title {
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 1.3;
        @include media(hover,601){
          font-size: 6.4rem;
        }
        @include media(screen,600){
          font-size: 5.2rem;
        }
      }
    }
  }
}

