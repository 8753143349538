.icon {
  &-like {
    width: 20px;
    height: 17px;
  }
  &-likes {
    width: 20px;
    height: 16px;
  }
  &-liked {
    width: 30px;
    height: 25px;
  }
  &-basket {
    width: 17px;
    height: 16px;
  }
  &-phone {
    width: 28px;
    height: 28px;
  }
  &-pdf {
    width: 30px;
    height: 30px;
  }
  &-loadFile {
    width: 24px;
    height: 24px;
  }
  &-remove {
    width: 18px;
    height: 19px;
  }
  &-feature {
    width: 20px;
    height: 18px;
  }
  &-delivery {
    .site-info & {
      width: 32px;
      height: 23px;
    }
    .compare-list & {
      width: 30px;
      height: 21px;
    }
  }
  &-changes {
    .site-info & {
      width: 32px;
      height: 32px;
    }
  }
  &-documents {
    .site-info & {
      width: 32px;
      height: 34px;
    }
    .compare-list & {
      width: 30px;
      height: 32px;
    }
  }
  &-money {
    .site-info & {
      width: 32px;
      height: 32px;
    }
    .compare-list & {
      width: 30px;
      height: 30px;
    }
  }
  &-forrussia {
    .compare-list & {
      width: 30px;
      height: 26px;
    }
  }
  &-vk-reviews {
    width: 64px;
    height: 54px;
  }
  &-arrow-left {
    width: 40px;
    height: 15px;
  }
  &-sign {
    font-style: normal;
    width: 16px;
    height: 16px;
    color: $link_hover;
    font-weight: 700;
    font-size: 13px;
    border: 1px solid $link_hover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}