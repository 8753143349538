.info-box {
  @include media(hover,1025){
    padding: 4*$padding_sm;
  }
  @include media(screen,1024){
    padding: 2*$padding_sm 0;
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
    @include media(hover,601){
      font-size: 6.4rem;
      margin-bottom: $padding_md;
    }
    @include media(screen,600){
      font-size: 5.2rem;
      margin-bottom: $padding_sm;
    }
  }
  &--text {
    font-size: 3.5rem;
    line-height: 1.5;
    @include media(hover,768){
      columns: 2 auto;
      column-gap: 2*$padding_sm;
    }
    a {
      color: $link_dark;
    }
    strong {
      font-weight: 700;
    }
  }

}
