.clearfix:after {content: ""; display: block; clear: both;}
.align-center {
  text-align: center;
}
.tableBox {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  & > div {
    display: table;
    width: 100%;
    height: 100%;
    & > div {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
    }
  }
}
.content {
  margin: 0 auto;
  box-sizing: border-box;
  display: block;
  @include media(hover,1367){padding: 0 2*$padding_md;}
  @include breakpoints(601,1366){padding: 0 2*$padding_sm;}
  @include media(screen,600){padding: 0 $padding_sm;}
  &--lg {
    max-width: 1920px;
  }
  &--md {
    max-width: 1460px;
  }
  &--sm {
    max-width: 1020px;
  }
  &--xs {
    max-width: 800px;
  }
  &.mobile-wide {
    @include media(screen,767){
      padding: 0;
    }
  }
}

.custom-row {
  display: flex;

  @include media(hover,1201){
    margin-left: -1*$padding_sm;
    margin-right: -1*$padding_sm;
  }
  @include media(screen,1200){
    flex-direction: column;
  }
  @include breakpoints(1025,1200){
    .project-page--tabs & {
      flex-direction: row;
      margin-left: -1*$padding_sm;
      margin-right: -1*$padding_sm;
    }
  }
  &-col {
    width: 100%;
    @include media(hover,1201){
      padding: 0 $padding_sm;
    }
    @include breakpoints(1025,1200){
      .project-page--tabs & {
        padding: 0 $padding_sm;
      }
    }
    &.col-elastic {
      @include media(hover,1201){
        max-width: calc(100% - 420px);
      }
    }
    &.col-projects {
      width: 230px;
      flex-shrink: 0;
      @include media(screen,1200){
        display: none;
      }
    }
    &.col-sidebar {
      @include media(hover,1201){
        width: 420px;
        flex-shrink: 0;
      }
    }
    &.col-50 {
      @include media(hover,1201){
        width: 50%;
        max-width: 50%;
      }
      @include breakpoints(1025,1200){
        .project-page--tabs & {
          width: 50%;
          max-width: 50%;
        }
      }
      @include media(screen,1024){
        &:last-child {
          margin-top: $padding_sm;
        }
      }
    }
    &.col-66 {
      @include media(hover,1201){
        width: 66.6667%;
        max-width: 66.6667%;
      }
    }
    &.col-33 {
      @include media(hover,1201) {
        width: 33.3333%;
        max-width: 33.3333%;
      }
    }
  }
}

.pt-sans {
  font-family: 'PT Sans', sans-serif;
}

.bg {
  background-color: $bg;
}

.hide {
  display: none !important;
}


.show-more-hidden {
  display: none;
  padding-bottom: 5px;
}



.desktop-hidden {
  @include media(hover,1201){
    display: none !important;
  }
}
.tablet-hidden {
  @include media(screen,1200){
    display: none !important;
  }
}
.mobile-hidden {
  @include media(screen,767){
    display: none !important;
  }
}
.mobile-show {
  @include media(hover,768){
    display: none !important;
  }
}

@keyframes anim_bg {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.gradient {
  overflow: hidden;
  position: relative;
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:before {
    will-change: transform;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 400%;
    background: $link;
    background: -moz-linear-gradient(left, $bg_blue_dark 0%, $link 25%, $bg_blue_dark 50%, $link 75%, $bg_blue_dark 100%);
    background: -webkit-linear-gradient(left, $bg_blue_dark 0%,$link 25%, $bg_blue_dark 50%, $link 75%, $bg_blue_dark 100%);
    background: linear-gradient(to right, $bg_blue_dark 0%,$link 25%, $bg_blue_dark 50%, $link 75%, $bg_blue_dark 100%);
    animation: anim_bg 8s infinite linear alternate;
    .mobile &,
    .tablet & {
      animation: none;
    }
  }
}

/* vendor */

