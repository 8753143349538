@import "project-tabs/tabs";

.project-page {
  padding: $padding_sm 0 0;
  margin-bottom: $padding_sm;
  @include media(screen,600){
    padding: 0;
  }
  &--head {
    align-items: stretch;
    @include media(hover,1601){
      margin-bottom: 2*$padding_sm;
    }
    @include breakpoints(1367,1600){
      margin-bottom: $padding_sm;
      .custom-row-col.col-33 {
        padding-left: 0;
      }
    }
    @include media(screen,1366){
      margin-bottom: 2*$padding_sm;
    }
    @include breakpoints(1201,1366){
      flex-direction: column;
      .custom-row-col.col-66,
      .custom-row-col.col-33 {
        max-width: none;
        width: 100%;
      }
    }
  }
  &--info {
    display: flex;
    flex-direction: column;
    height: 100%;
    &.temp {
      @include media(hover,1601){
        display: none;
      }
      @include breakpoints(1367,1600){
        margin-bottom: 2*$padding_sm;
        height: auto;
        .data {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .info {
          margin-bottom: 0;
        }
      }
      @include media(screen,1366){
        display: none;
      }
    }
    &.static {
      @include breakpoints(1367,1600){
        .info,
        .estimate {
          display: none;
        }
      }
    }
    .main-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $padding_sm;
      flex-shrink: 0;
      .index {
        background-color: $text;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        white-space: nowrap;
        @include media(hover,1601){
          font-size: 8.5rem;
          padding: 0 2*$padding_sm;
          margin-right: 2*$padding_sm;
        }
        @include media(screen,1600){
          font-size: 7rem;
          padding: 0 10px;
        }
        @include media(hover,601){
          height: 80px;
        }
        @include media(screen,600){
          height: 60px;
        }
      }
      .price {
        width: 50%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media(screen,1600){
          padding: 0 10px;
        }
      }
      .current-price {
        font-family: 'PT Sans', sans-serif;
        font-weight: 600;
        white-space: nowrap;
        @include media(hover,1601){
          font-size: 8.5rem;
        }
        @include media(screen,1600){
          font-size: 6rem;
        }
      }
      .old-price {
        position: relative;
        font-size: 3rem;
        color: $border;
        display: flex;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          height: 1px;
          background-color: currentColor;
        }
      }

    }
    .data {
      height: 100%;
      background-color: $bg_blue;
      @include media(hover,1601){
        padding: $padding_md;
      }
      @include media(screen,1600){
        padding: $padding_sm;
      }
      @include breakpoints(768,1200){
        display: flex;
        &-col {
          max-width: 50%;
          width: 50%;
          &:first-child {
            padding-left: 10px;
          }
          &:last-child {
            padding-right: 10px;
            order: -1;
          }
        }
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media(hover,1601){margin-bottom: 2*$padding_sm;}
        @include media(screen,1600){margin-bottom: $padding_sm;}
        @include media(screen,1200){
          max-width: 420px;
          margin-left: auto;
          margin-right: auto;
        }
        .icon-liked {
          flex-shrink: 0;
          margin-left: $padding_md;
          color: transparent;
          background: none;
          border: none;
          cursor: pointer;
          @include hover(){
            color: $link;
          }
          &.liked {
            color: $link_dark;
          }
        }
      }
      .feature {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;

        padding: 8px 0;
        @include media(hover,1601){  font-size: 3.3rem;}
        @include media(screen,1600){  font-size: 3rem;}
        @include media(hover,1367){
          justify-content: space-between;
          margin-bottom: $padding_md;
        }
        @include media(screen,1366){
          justify-content: center;
        }
        @include breakpoints(1201,1366){
          margin-bottom: $padding_sm;
        }
        @include media(scree, 767){
          margin-bottom: $padding_sm;
        }
        .icon-feature {
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
    }
    .info {
      display: flex;
      align-items: flex-start;
      color: $text;
      font-size: 4rem;
      @include media(hover,1367){
        margin-bottom: $padding_md;
      }
      @include media(screen,1366){
        justify-content: center;
        margin-bottom: $padding_sm;
      }
      .icon-sign {
        flex-shrink: 0;
        margin: 5px 0 0 0;
      }
      .text {
        font-weight: 600;
      }
      ul {
        display: flex;
        align-items: stretch;
        text-align: center;
        @include media(hover,1367){
          width: 100%;
        }
      }
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:last-child){
          border-right: 1px solid currentColor;
        }
        @include media(hover,601){
          padding: 0 15px 10px;
        }
        @include media(screen,600){
          padding: 0 10px 10px;
        }
      }
    }
    .estimate {
      display: flex;
      @include media(screen,1366){
        justify-content: center;
      }
    }
  }
  &--slider {
    width: 100%;
    padding-top: 60%;
    position: relative;
    @include media(screen,1366){
      margin-bottom:  $padding_sm;
    }
    @include media(screen,600){
      padding-top: 75%;
    }
  }
  &--reviews {
    position: relative;
    background-color: $link_hover;
    color: $white;
    min-height: 60px;
    margin-bottom: 2*$padding_sm;
    display: flex;
    align-items: center;
    padding: 0 $padding_sm;
    @include media(screen,600){
      flex-wrap: wrap;
    }
    .icon {
      margin-right: $padding_sm;
      transform: translateY(-30px);
      @include media(screen,600){
        margin-bottom: -20px;
      }
    }
    .text {
      font-family: 'Merriweather';
      font-size: 5rem;
      font-style: italic;
      font-weight: 300;
    }
    .read {
      background-color: $white;
      color: $link_hover;
      font-size: 4rem;
      font-weight: 600;
      padding: 8px $padding_md;
      border-radius: 30px;
      @include hover(){
        color: $link_dark;
      }
      @include media(hover,601){
        margin-left: 2*$padding_sm;
      }
      @include media(screen,600){
        margin: $padding_sm auto;
      }
    }
  }
  &--about {
    width: 100%;
    @include media(hover,1025){
      max-width: 85%;
    }
    .title {
      font-size: 6.4rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: $padding_sm;
    }
    .text-box {
      @include media(hover,1025){
        width: 80%;
        margin-bottom: 4*$padding_sm;
      }
      @include media(screen,1024){
        margin-bottom: 2*$padding_md;
      }
      figure {
        @include media(hover,1025){
          margin-right: -25%;
        }
      }
    }
  }
  .catalog-actions {
    @include media(hover,1025){
      max-width: 85%;
    }
  }
}

.fancybox-button--play,
.fancybox-button--zoom {
  display: none !important;
}
.fancybox-button {
  background: transparent;
}

.fancybox-navigation .fancybox-button {
  background-color: rgba($white,0.7);
  background-clip: border-box;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 10px 5px rgba($border,0.16);
  visibility: visible;
  outline: none;
  padding: 0 !important;
  @include hover(){
    background-color: $white;
  }
  @include media(hover,601){
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
  }
  @include media(screen,600){
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
  }
  div {
    display: none !important;
  }
  &[disabled]{
    display: none !important;
    background-color: rgba($white,0.4);
    pointer-events: none;
  }
  &--arrow_right {
    @include media(hover,601){
      right: 5%;
    }
    &:before {
      content: "";
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%20339%20339%22%20shape-rendering%3D%22geometricPrecision%22%20image-rendering%3D%22optimizeQuality%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22m145%2039c-2-5-7-6-11-4-4%202-6%207-4%2011l62%20124-62%20123c-2%204%200%209%204%2011%204%202%209%200%2012-4l63-126c1-3%201-5%200-8l-64-127%22%20fill%3D%22%23657482%22%20fill-rule%3D%22nonzero%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 3px;
      right: 0;
      bottom: 0;
      @include media(hover,601){
        background-size: 40px auto;
      }
      @include media(screen,600){
        background-size: 20px auto;
      }
    }
  }
  &--arrow_left {
    @include media(hover,601){
      left: 5%;
    }
    &:before {
      content: "";
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%20339%20339%22%20shape-rendering%3D%22geometricPrecision%22%20image-rendering%3D%22optimizeQuality%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22m194%2039c2-5%207-6%2011-4%204%202%206%207%204%2011l-62%20124%2061%20123c2%204%201%209-4%2011-4%202-9%200-11-4l-63-126c-1-3-1-5%200-8l64-127%22%20fill%3D%22%23657482%22%20fill-rule%3D%22nonzero%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      right: 3px;
      bottom: 0;
      @include media(hover,601){
        background-size: 40px auto;
      }
      @include media(screen,600){
        background-size: 20px auto;
      }
    }
  }
}

