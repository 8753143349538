.sharing {
  display: flex;
  align-items: center;
  @include media(screen,767){
    display: none;
  }
  &.title-end {
    flex-direction: row-reverse;
    margin-left: auto;
    li {
      @include media(hover,768){
        margin: 0 $padding_sm 0 0;
      }
    }
  }
  .title {
    color: $border;
    text-transform: uppercase;
    font-size: 3.5rem;
    @include media(screen,767){
      display: none;
    }
  }
  ul {
    display: flex;
    align-items: center;
  }
  li {
    @include media(hover,768){
      margin: 0 0 0 $padding_sm;
    }
    @include media(screen,767){
      margin: 0 10px;
    }
  }
  .ico {
    display: flex;
    color: $white;
    align-items: center;
    justify-content: center;
    @include media(hover,601){
      width: 40px;
      height: 40px;
    }
    @include media(screen,600){
      width: 34px;
      height: 34px;
    }
    &-fb {
      background-color: #455896;
      padding: 7px;
      @include hover(){
        background-color: #455896 * 0.9;
      }
    }
    &-gg {
      background-color: #c14f44;
      padding: 7px;
      @include hover(){
        background-color: #c14f44 * 0.9;
      }
    }
    &-vk {
      background-color: #556f9c;
      padding: 7px;
      @include hover(){
        background-color: #556f9c * 0.9;
      }
    }
    &-ok {
      background-color: #d3732f;
      padding: 7px;
      @include hover(){
        background-color: #d3732f * 0.9;
      }
    }
  }
}