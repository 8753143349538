.text-box {
  font-size: 4rem;
  line-height: 1.6;
  h1,h2,h3,h4,h5,h6 {
    margin-bottom: $padding_sm;
    font-weight: 600;
    line-height: 1.3;
  }
  h1 { font-size: 7.2rem; }
  h2 { font-size: 6.8rem; }
  h3 { font-size: 6.4rem; }
  p,ul {
    margin-bottom: $padding_md;
  }
  li {
    margin-bottom: 10px;
    position: relative;
    @include media(hover,601){
      padding-left: 2*$padding_sm;
    }
    @include media(screen,600){
      padding-left: 25px;
    }
    &:before {
      content: "\2022";
      position: absolute;
      left: 0;
      top: 0;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .left-align {
    margin-bottom: $padding_md;
    width: 100%;
    @include media(hover,1025){
      margin-right: -25%;
    }
    @include media(hover,601){
      float: left;
      margin-right: $padding_md;
      max-width: 50%;
    }
  }
  .right-align {
    margin-bottom: $padding_md;
    width: 100%;
    @include media(hover,1025){
      margin-right: -25%;
    }
    @include media(hover,601){
      float: right;
      margin-left: $padding_md;
      max-width: 50%;
    }
  }
  img {
    display: block;
    max-width: 100%;
    height: auto !important;
  }
  figure {
    margin-bottom: $padding_md;
    img {
      width: 100%;
    }
  }
  a {
    color: inherit;
    background: linear-gradient(to bottom, $link 0%, $link 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 1px 2px;
    padding: 0 1px;
    &:hover {
      @include media(hover,1025){
        color: $white;
        background-size: 1px 100%;
      }
    }
  }
  blockquote {
    p {margin: 0;}
    position: relative;
    font-family: 'Merriweather';
    @include media(hover,601){
      margin: 2*$padding_md 0;
      padding: 0 2*$padding_sm 0 4*$padding_sm;
      font-size: 5.2rem;
    }
    @include media(screen,600){
      font-size: 4.5rem;
      margin: 2*$padding_sm 0;
      padding: 0 $padding_sm 0 $padding_md;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      background-color: $link;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    @include media(hover,601){
      margin-bottom: 2*$padding_md;
    }
    @include media(screen,600){
      font-size: 3rem;
      margin-bottom: 2*$padding_sm;
    }
    tr {
      border-top: 1px solid #e1e3e7;
      &:last-child {
        border-bottom: 1px solid #e1e3e7;
      }
    }
    td {
      width: 50%;
      @include media(hover,601){
        padding: 5px $padding_md 5px 0;
      }
      @include media(screen,600){
        padding: 5px 10px 5px 0;
      }
    }
  }
}