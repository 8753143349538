.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.2;
  border: none;
  white-space: nowrap;
  @include media(hover,1201){
    height: 60px;
    padding: 0 2*$padding_md;
  }
  @include breakpoints(768,1200){
    height: 50px;
    padding: 0 2*$padding_sm;
  }
  @include media(screen,767){
    height: 40px;
    padding: 0 20px;
  }
  .arrow {
    width: 30px;
    height: 30px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-color: currentColor;
      border-width: 2px;
      border-style: solid solid none none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg);
    }
  }
  &--round {
    background-color: $link;
    color: $white;
    border-radius: 60px;
    @include hover(){
      background-color: $link * 0.95;
    }
  }
  &--white {
    background-color: $white;
    color: $link;
    text-transform: uppercase;
    @include hover(){
      color: $link * 0.95;
    }
  }
  &--lt {
    background-color: $link;
    color: $white;
    text-transform: uppercase;
    @include hover(){
      background-color: $link * 0.95;
    }
  }
  &--sort {
    background-color: $link;
    color: $white;
    text-transform: uppercase;
    @include hover(){
      background-color: $link * 0.95;
    }
    span {
      position: relative;
      //padding-right: 20px;
      //&:after {
      //  content: "";
      //  position: absolute;
      //  width: 9px;
      //  height: 9px;
      //  border-color: $white;
      //  border-width: 2px;
      //  border-style: solid solid none none;
      //  top: 0;
      //  right: 0;
      //  bottom: 0;
      //  margin: auto;
      //  transform: rotate(135deg) translate(-2px,2px);
      //  transition: all 0.3s;
      //  .show & {
      //    transform: rotate(315deg) translate(-2px,2px);
      //  }
      //}
    }
  }
  &--og {
    background-color: $hash;
    color: $white;
    text-transform: uppercase;
    @include hover(){
      background-color: $hash * 0.95;
    }
  }
  &--dk {
    background-color: $link_dark;
    color: $white;
    text-transform: uppercase;
    @include hover(){
      background-color: $link_dark * 0.95;
    }
  }

  &-add {
    border: 1px solid $border;
    color: $border;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    line-height: 1.2;
    font-size: 3rem;
    font-weight: 700;
    height: 30px;
    padding: 0 $padding_sm;
    white-space: nowrap;
    &:before {
      content: "+";
      margin-right: 5px;
    }
    @include hover(){
      border-color: $text;
      color: $text;
    }
  }
  &-reset {
    border: 1px solid $border;
    color: $border;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    line-height: 1.2;
    font-size: 3rem;
    font-weight: 700;
    height: 40px;
    padding: 0 $padding_sm;
    white-space: nowrap;
    background-color: transparent;
    width: 100%;
    justify-content: center;
    span {
      font-size: 5rem;
      font-weight: 400;
      margin-right: 5px;
    }
    @include hover(){
      border-color: $text;
      color: $text;
    }
  }
  &-square-min {
    border: none;
    background-color: $link;
    color: $white;
    line-height: 1.2;
    font-size: 3rem;
    font-weight: 700;
    padding: 0 $padding_sm;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    width: 100%;
    @include hover(){
      background-color: $link * 0.95;
    }
    &.incart {
      background-color: $link_dark;
      pointer-events: none;
    }
  }
  &-square-dark {
    border: none;
    background-color: $link_dark;
    color: $white;
    line-height: 1.2;
    font-size: 3.5rem;
    font-weight: 700;
    padding: 0 $padding_sm;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    @include hover(){
      background-color: $link_dark * 0.95;
    }
  }
  &-round-min {
    background-color: $link;
    color: $white;
    border-radius: 60px;
    line-height: 1.2;
    font-size: 4rem;
    font-weight: 700;
    padding: 0 $padding_sm;
    height: 40px;
    display: flex;
    align-items: center;
    @include hover(){
      background-color: $link * 0.95;
    }
  }
  &-round-min-orange {
    background-color: $hash;
    color: $white;
    border-radius: 60px;
    line-height: 1.2;
    font-size: 3.6rem;
    font-weight: 600;
    padding: 0 $padding_sm;
    height: 40px;
    display: flex;
    align-items: center;
    @include hover(){
      background-color: $hash * 0.95;
    }
  }
  &-small {
    white-space: nowrap;
    color: $border;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid currentColor;
    height: $padding_md;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    @include hover(){
      color: $white;
      background-color: $link_dark;
      border-color: $link_dark;
    }
    &.incart {
      background-color: $link_dark;
      border-color: $link_dark;
      pointer-events: none;
      color: $white;
    }
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2*$padding_sm;
    .btn {
      @include media(hover,601){
        min-width: 400px;
      }
      @include media(screen,600){
        width: 100%;
      }
      & + .btn {
        @include media(hover,601){
          margin-left: 20px;
        }
        @include media(screen,600){
          margin-left: 10px;
        }
      }
    }
    &.multiple {
      .btn {
        @include breakpoints(768,1024){
          min-width: 250px;
        }
        @include media(screen,767){
          min-width: 0;
          width: 100%;
          font-size: 3rem;
        }
      }
    }
  }
}
