/*******************************************/
/*                VIDEO BOX                */
/*******************************************/

.video-box {
  position: relative;
  @include media(screen,767){
    //height: calc(100vh - 60px);
  }
  @include breakpoints(601,767){
    margin-bottom: 2*$padding_sm;
  }
  @include media(screen,600){
    margin-bottom: $padding_sm;
  }
  &--bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
    @include media(hover,768){
      bottom: -100px;
    }
    @include media(screen,767){
      bottom: 0;
    }
    figure {
      width: 100%;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include media(hover,768){
          &.img-mobile {
            display: none;
          }
        }
        @include media(screen,767){
          &.img-desktop {
            display: none;
          }
        }

      }
    }
    video {
      position: absolute;
      min-height: 100%;
      min-width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &--wrap {
    z-index: 3;
    @include media(hover,768){
      position: relative;
      min-height: 420px;
      height: calc(100vh - 320px);
      display: flex;
      align-items: center;
      width: 100%;
    }
    @include media(screen,767){
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 20px;
    }
  }
  &--about {
    background-color: $link_hover;
    color: $white;
    @include media(hover,1201){
      width: 37.5%;
    }
    @include breakpoints(768,1200){
      width: 50%;
    }
    @include media(screen,767){
      width: 100%;
    }
    section {
      font-size: 4rem;
      font-weight: 300;
      @include media(hover,1367){
        padding: 2*$padding_sm 2*$padding_md;
      }
      @include breakpoints(1025,1366){
        padding: $padding_md 2*$padding_sm;
      }
      @include breakpoints(601,1024){
        padding: $padding_md;
      }
      @include media(screen,600){
        padding: $padding_sm;
      }
    }
    .title {
      margin-bottom: 4rem;
    }
    .actions {
      display: flex;
      .btn {
        @include media(hover,768){
          width: 50%;
        }
        @include media(screen,767){
          border-radius: 30px;
        }
      }
      @include media(screen,767){
        justify-content: center;
        padding: 0 20px 20px;
        .btn--lt {
          display: none;
        }
      }
    }
  }
}

/***********************************************/
/*                SITE INFO BOX                */
/***********************************************/

.site-info {
  @include media(hover,601){margin-bottom: 2*$padding_sm;}
  @include media(screen,600){margin-bottom: $padding_sm;}
  &--wrap {
    background-color: $white;
    @include media(hover,1367){
      padding: 2*$padding_sm 2*$padding_md;
    }
    @include breakpoints(1025,1366){
      padding: $padding_md 2*$padding_sm;
    }
    @include breakpoints(768,1024){
      padding: $padding_sm;
    }
    @include media(hover,768){
      box-shadow: 0 30px 40px 5px rgba($border,0.16);
    }
    @include breakpoints(601,767){
      padding: 10px;
      box-shadow: 0 0 40px 5px rgba($border,0.16);
    }
    position: relative;
    z-index: 2;
    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include media(screen,1024){
        flex-flow: wrap row;
      }
    }
    li {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &:not(:last-child){
        @include media(hover,1601){
          margin-right: 2*$padding_md;
        }
        @include breakpoints(1025,1600){
          margin-right: 2*$padding_sm;
        }
      }
      @include breakpoints(601,1024){
        padding: $padding_sm;
        width: 50%;
      }
      @include media(screen,600){
        padding: $padding_sm 0;
        width: 100%;
      }
    }
  }
  &--text {
    width: 100%;
    flex-shrink: 500;
    font-size: 3.5rem;
  }
  .icon {
    margin-right: $padding_sm;
    &-delivery {
      margin-top: 4.5px;
    }
  }
  .title {
    font-weight: 600;
    margin-bottom: 10px;
    @include media(hover,1601){
      font-size: 5.2rem;
    }
    @include media(screen,1600){
      font-size: 4.8rem;
    }
  }
}

/*******************************************/
/*                ABOUT BOX                */
/*******************************************/

.home-about {

  @include media(hover,768){
    padding: $padding_md 0;
    margin-bottom: 2*$padding_sm;
  }
  @include media(screen,767){
    margin-bottom: $padding_sm;
  }
  &-menu {
    position: relative;
    z-index: 2;
    @include media(hover,768){
      margin-bottom: 2*$padding_md;
    }
    @include media(screen,767){
      margin-bottom: $padding_sm;
    }
    &--wrap {
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      @include media(hover,768){
        margin: 0 -1*$padding_sm;
      }
      @include media(screen,767){
        flex-direction: column;
      }
      li {
        @include media(hover,1201){
          padding: 0 $padding_sm;
        }
        @include breakpoints(768,1200){
          padding: 0 10px;
        }
        @include media(hover,1025){
          width: 25%;
        }
        @include breakpoints(768,1024){
          width: 33.3333%;
        }
        @include media(screen,767){
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    &--item {
      width: 100%;
      display: block;
      position: relative;
      background-color: $bg;
      @include media(hover,768){
        padding-top: 66.6667%;
      }
      article {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        text-align: center;
        @include media(hover,1721){
          padding: 23% $padding_sm 0;
          justify-content: flex-start;
        }
        @include breakpoints(1601,1720){
          padding: 15% $padding_sm 0;
          justify-content: flex-start;
        }
        @include breakpoints(768,1600){
          padding: 10px $padding_sm;
          justify-content: center;
        }
        @include media(hover,768){
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        @include media(screen,767){
          padding: $padding_md 0;
        }
      }
      .title {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 10px;
        @include media(hover,1601){
          font-size: 6.4rem;
        }
        @include breakpoints(1201,1600){
          font-size: 5.8rem;
        }
        @include media(screen,1200){
          font-size: 4rem;
        }
      }
      .subtitle {
        font-size: 3.5rem;
        color: $border;
        @include media(hover,1201){
          margin-bottom: 20px;
        }
        @include media(screen,1200){
          margin-bottom: 15px;
        }
      }
      .read {
        display: flex;
        justify-content: center;
        transition: all 0.3s;
        @include media(hover,1025){
          opacity: 0;
        }
        @include media(screen,1024){
          opacity: 1;
        }
      }
      @include hover(){
        background-color: $bg_blue;
        .read {opacity: 1;}
      }
    }
  }
  &-main {
    position: relative;
    .img-branch {
      @include media(hover,768){
        position: absolute;
        left: 0;
        bottom: 15%;
        width: 230px;
        height: auto;
        z-index: 1;
      }
      @include media(screen,767){
        display: none;
      }
    }
    &--wrap {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      @include media(hover,768){
        margin: 0 -1*$padding_sm;
      }
    }
    &--chief {
      @include media(hover,1025){
        width: 25%;
      }
      @include media(screen,1024){
        width: 33.3333%;
      }
      @include media(hover,768){
        padding: 2*$padding_sm $padding_sm;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      @include media(screen,767){
        display: none;
      }
      &:before {
        content: "";
        top: 0;
        bottom: 0;
        right: 50%;
        width: 200%;
        background-color: $hash;
        position: absolute;
      }
      & > * {
        position: relative;
        z-index: 1;
      }
    }
    &--photo {

      border-radius: 50%;
      overflow: hidden;

      @include media(hover,1367){
        margin-bottom: $padding_md;
        width: 240px;
        height: 240px;
      }
      @include media(screen,1366){
        margin-bottom: $padding_sm;
        width: 200px;
        height: 200px;
      }
      img {
        @extend %fullBlock;
        border-radius: 50%;
      }
    }
    &--post {
      width: 50%;
      margin-left: 50%;
      padding-left: 10px;
      font-size: 3.5rem;
      font-weight: 700;
      line-height: 1.6;

    }
    &--speech {

      @include media(hover,1025){
        width: 50%;
      }
      @include breakpoints(768,1024){
        width: 66.6667%;
      }
      @include media(hover,768){
        padding: 2*$padding_sm $padding_sm;
      }
      .text {
        font-family: Merriweather;
        font-style: italic;
        @include media(hover,1201){
          font-size: 5rem;
        }
        @include media(screen,1200){
          font-size: 4.5rem;
        }
        p {
          @include media(hover,768){margin-bottom: 2*$padding_sm;}
          @include media(screen,767){margin-bottom: $padding_sm;}
        }
      }
      .blog-hashes {
        @include media(screen,767){
          display: none;
        }
      }
    }
  }
}

/*********************************************/
/*                OTHER BOXES                */
/*********************************************/

.home-info-box,
.home-blog-slider {
  @include media(screen,600){
    display: none;
  }
}