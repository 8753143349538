.request {
  &--wrap {
    padding-top: 2*$padding_sm;
    .title {
      text-align: center;
      color: $white;
      margin-bottom: 2*$padding_sm;
    }
    .subtitle {
      font-style: italic;
      font-size: 5.2rem;
      color: $white;
      font-family: 'Merriweather';
      margin-bottom: 2*$padding_sm;
      a {
        display: inline-block;
        white-space: nowrap;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 1px;
          left: 0;
          right: 0;
          bottom: 3px;
          background-color: $white;
          transition: all 0.3s;
        }
        @include hover(){
          &:after {
            background-color: transparent;
          }
        }
      }
      .successful & {
        display: none;
      }
    }
    .success-text {
      font-style: italic;
      font-size: 5.2rem;
      color: $white;
      font-family: 'Merriweather';
      margin-bottom: 2*$padding_sm;
      display: none;
      .successful & {
        display: block;
      }
    }
  }
}

.request-form {
  &--main {
    background-color: $white;
    position: relative;
    z-index: 1;
    @include media(hover,1025){
      padding: $padding_md 2*$padding_sm;
    }
    @include breakpoints(601,1024){
      padding: $padding_sm $padding_md;
    }
    @include media(screen,600){
      padding: 10px $padding_sm;
    }
  }
  &--checks {
    background-color: $bg;
    @include media(hover,1025){
      padding: $padding_md 2*$padding_sm;
    }
    @include breakpoints(601,1024){
      padding: $padding_sm $padding_md;
    }
    @include media(screen,600){
      padding: 10px $padding_sm;
    }
    position: relative;

  }
  &--submit {
    background-color: $bg;

    overflow: hidden;
    position: relative;
    border-top: 1px solid $text_lt;
    @include media(hover,1025){
      padding: 0 2*$padding_sm;
    }
    @include breakpoints(601,1024){
      padding: 0 $padding_md;
    }
    @include media(screen,600){
      padding: 5px $padding_sm 10px;
    }
    .form-row-submit {
      @include media(hover,1025){
        margin-right: -2*$padding_sm - 1px;
      }
      @include media(screen,1024){
        margin: 10px 0;
      }
    }
  }
  &--bottom {
    position: relative;
    box-shadow: 0 30px 80px 0 rgba(26,26,26,0.2);
  }
  .filter-form {
    position: relative;
    @include media(hover,1025){
      padding-bottom: 3*$padding_md;
    }
    @include breakpoints(601,1024){
      padding-bottom: 2*$padding_md;
    }
    @include media(screen,600){
      padding-bottom: 2*$padding_sm;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 100vw;
      transform: translateX(-50vw);
      background-color: $white;

    }
  }
}