.project-page {
  .tab-video {
    .video {
      width: 100%;
      @include media(hover,1201){
        max-width: 85%;
      }
    }
    figure {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 50%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}