.file {
  display: flex;
  width: 100%;
  position: relative;
  color: $link_hover;
  align-items: center;
  .icon-loadFile {
    margin-right: 5px;
    flex-shrink: 0;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 100%;
    position: relative;
    span {
      font-size: 3.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .remove {
    margin-left: 5px;
    font-style: normal;
    cursor: pointer;
    align-self: flex-start;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }

}

