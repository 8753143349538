.owl-carousel {
  .owl-prev,
  .owl-next {
    background-color: $white;
    border-radius: 50%;
    border: none;
    position: absolute;
    box-shadow: 0 0 10px 5px rgba($border,0.16);
    transform: translateY(-50%);
    @include media(hover,601){
      width: 60px;
      height: 60px;
    }
    @include media(screen,600){
      width: 40px;
      height: 40px;
    }
  }
  .owl-prev {
    @include media(hover,601){
      left: -30px;
    }
    @include media(screen,600){
      left: -15px;
    }
    &:before {
      content: "";
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%20339%20339%22%20shape-rendering%3D%22geometricPrecision%22%20image-rendering%3D%22optimizeQuality%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22m194%2039c2-5%207-6%2011-4%204%202%206%207%204%2011l-62%20124%2061%20123c2%204%201%209-4%2011-4%202-9%200-11-4l-63-126c-1-3-1-5%200-8l64-127%22%20fill%3D%22%23657482%22%20fill-rule%3D%22nonzero%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      right: 3px;
      bottom: 0;
      @include media(hover,601){
        background-size: 40px auto;
      }
      @include media(screen,600){
        background-size: 20px auto;
      }
    }
  }
  .owl-next {
    @include media(hover,601){
      right: -30px;
    }
    @include media(screen,600){
      right: -15px;
    }
    &:before {
      content: "";
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%20339%20339%22%20shape-rendering%3D%22geometricPrecision%22%20image-rendering%3D%22optimizeQuality%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22m145%2039c-2-5-7-6-11-4-4%202-6%207-4%2011l62%20124-62%20123c-2%204%200%209%204%2011%204%202%209%200%2012-4l63-126c1-3%201-5%200-8l-64-127%22%20fill%3D%22%23657482%22%20fill-rule%3D%22nonzero%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 3px;
      right: 0;
      bottom: 0;
      @include media(hover,601){
        background-size: 40px auto;
      }
      @include media(screen,600){
        background-size: 20px auto;
      }
    }
  }
}