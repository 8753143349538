.contact-page {
  @include media(hover,768){
    margin-bottom: 2*$padding_md;
  }
  @include media(screen,767){
    margin-bottom: 2*$padding_sm;
  }
  &--wrap {
    padding-top: 2*$padding_sm;
    background: #ff6600;
    .title {
      text-align: center;
      color: $white;
      margin-bottom: 2*$padding_sm;
    }
  }
  &--data {
    color: $white;
    @include media(hover,1201){
      padding: 0 2*$padding_sm;
      margin-bottom: 2*$padding_md;
    }
    @include media(screen,1200){
      margin-bottom: 2*$padding_sm;
    }
    .dd {
      margin-bottom: 5px;
      @include media(hover,1025){
        min-height: 60px;
      }
      @include media(screen,1024){
        font-size: 5rem;
      }
      a {
        @extend %hover_underline;
      }
    }
    .dt {
      font-size: 3.5rem;
      font-family: "Merriweather";
      @include media(screen,1024){
        margin-top: 5px;
      }
    }
    .list {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include media(screen,1024){
        flex-direction: column;
      }
      li {
        @include media(screen,1024){
          display: flex;
          flex-direction: column;
          .dt {
            margin-bottom: 5px;
          }
        }
        &:not(:last-child){
          @include media(hover,1025){
            margin-right: $padding_sm;
          }
          @include media(screen,1024){
            margin-bottom: 15px;
          }
        }
      }
    }
    .tel {
      font-weight: 600;
      display: flex;
      align-items: center;
      white-space: nowrap;
      @include media(hover,601){
        font-size: 8.5rem;
      }
      @include media(screen,600){
        font-size: 7rem;
      }
      .icon {
        margin-right: 20px;
        @include media(screen,600){
          display: none;
        }
      }
    }
    .socials {
      text-transform: uppercase;
    }
    .col-tel {
      .dt {
        @include media(hover,1025){
          padding-left: 48px;
        }
        @include media(screen,1024){
          margin-right: 30px;
        }
      }
      @include media(screen,1024){
        display: flex;
        margin-bottom: 15px;
        flex-direction: column;
      }
    }
  }
  &--partners {
    margin-bottom: 2*$padding_md;
    ul {
      column-gap: 2*$padding_sm;
      @include media(hover,1367){
        columns: 6 auto;
        .projects-compilation & {
          columns: 2 auto;
        }
      }
      @include breakpoints(1201,1366){
        columns: 4 auto;
        .projects-compilation & {
          columns: 2 auto;
        }
      }
      @include breakpoints(1025,1200){
        columns: 3 auto;
        .projects-compilation & {
          columns: 2 auto;
        }
      }
      @include breakpoints(768,1024){
        columns: 3 auto;
        .projects-compilation & {
          columns: 2 auto;
        }
      }
      @include breakpoints(601,767){
        columns: 2 auto;
        .projects-compilation & {
          columns: 1;
        }
      }
      @include media(screen,600){
        columns: 1 auto;
        column-gap: $padding_sm;
        .projects-compilation & {
          columns: 1;
        }
      }
      li {
        padding: 5px 0;
        display: inline-block;
        width: 100%;
      }
      a {
        @extend %hover_underline;
      }
    }
  }
}

.contact-form {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    width: 100vw;
    transform: translateX(-50vw);
    background-color: $white;
  }
  &--wrap {
    background-color: $white;
    position: relative;
    box-shadow: 0 30px 80px 0 rgba(26,26,26,0.2);
  }
  &--main {
    position: relative;
    z-index: 1;
    @include media(hover,1025){
      padding: 1.5*$padding_sm 2*$padding_sm 10px;
    }
    @include breakpoints(601,1024){
      padding: $padding_sm $padding_md;
    }
    @include media(screen,600){
      padding: 10px $padding_sm;
    }
  }
  &--submit {

    position: relative;
    border-top: 1px solid $text_lt;
    @include media(hover,1025){
      padding: 0 2*$padding_sm;
    }
    @include breakpoints(601,1024){
      padding: 0 $padding_md;
    }
    @include media(screen,600){
      padding: 5px 20px 10px;
    }
    .form-row-submit {
      @include media(hover,1025){
        margin-right: -2*$padding_sm - 1px;
      }
      @include media(screen,1024){
        margin: 10px 0;
      }
    }
  }
}