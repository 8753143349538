.radio-flex {
  display: flex;
  width: 100%;
  label {
    width: 100%;
    position: relative;
    cursor: pointer;
    &:not(:last-child){
      border-right: 1px solid $bg;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      &:checked {
        & ~ span {
          background-color: $link;
          color: $white;
        }
      }
    }
    span {
      background-color: $white;
      color: $border;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

