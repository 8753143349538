.service {
  &--head {
    @include media(hover,1025){
      margin-bottom: 2*$padding_sm;
    }
    @include media(screen,1024){
      margin-bottom: $padding_sm;
    }
  }
  &--wrap {
    padding-top: 2*$padding_sm;
    .title {
      text-align: center;
      color: $white;
      margin-bottom: 2*$padding_sm;
    }
    .subtitle {
      font-style: italic;
      font-size: 5.2rem;
      color: $white;
      font-family: 'Merriweather';
      margin-bottom: 2*$padding_sm;
      a {
        display: inline-block;
        white-space: nowrap;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 1px;
          left: 0;
          right: 0;
          bottom: 3px;
          background-color: $white;
        }
      }
    }
  }
  &--profit {
    max-width: 1000px;
    margin: 0 auto 2*$padding_sm;
    .title {
      font-size: 6.4rem;
      font-weight: 600;
      margin-bottom: $padding_sm;
    }
    .examples {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      @include media(hover,1025){
        margin: 0 -2*$padding_md;
      }
      &-box {

      }
      li {
        @include media(hover,1025){
          padding: 10px 2*$padding_md;
        }
        @include breakpoints(601,1024){
          padding: 10px 2*$padding_sm 10px 0;
        }
        @include media(hover,601){
          width: 50%;
        }
        @include media(screen,600){
          padding: 10px 0;
        }
      }
      a {
        font-size: 4rem;
        display: flex;
        align-items: center;
        color: $link_dark;
        @include hover(){
          color: $link_dark * 0.8;
          .icon-pdf {
            transform: scale(1);
          }
        }
      }
      .icon-pdf {
        margin-right: 10px;
        flex-shrink: 0;
        transform: scale(0.95);
        transition: all 0.2s;
      }
    }
    .text-box {
      margin-bottom: $padding_md;
    }
  }
}

.services-slider {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: calc(50% - 20px);
    bottom: 0;
    left: 50%;
    width: 100vw;
    transform: translateX(-50vw);
    background-color: $white;
  }
  .owl-prev,
  .owl-next {
    top: 50%;
  }
  .slide-counter {
    position: relative;
    font-size: 3.5rem;
    height: 40px;
    display: flex;
    align-items: center;
    color: $border;
    font-weight: 600;
  }
}


.services-more {
  margin-bottom: 2*$padding_sm;
  &--wrap {
    padding: 2*$padding_sm 0 $padding_sm;
    .title {
      text-transform: uppercase;
      font-size: 4rem;
      color: $border;
      font-weight: 600;
      margin-bottom: 2*$padding_sm;
    }
  }
}