@import "home";
@import "blog";
@import "request";
@import "about";
@import "service";
@import "comparison";
@import "basket";
@import "catalog";
@import "project-page";
@import "article-page";
@import "partner-page";
@import "collaboration";
@import "contact-page";
@import "search-page";
@import "profile";
@import "referral";


.text-section {
  margin: 2*$padding_sm 0 2*$padding_md;
}