html {
  @include media(hover,1367){font-size: 4.2px;}
  @include media(screen,1366){font-size: 3.7px;}
  @include media(hover,1201){
    height: 100%;
  }
  &.ovh {
    overflow: hidden;
  }
  //@include media(hover,1201){font-size: 14px;}
  //@include breakpoints(1025,1200){font-size: 13px;}
  //@include breakpoints(768,1024){font-size: 12px;}
  //@include media(screen,767){font-size: 11px;}
}
body {
  line-height: 1.4;
  font-weight: 400;
  color: $text;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  font-size: 16px;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
a, button {
  color: inherit;
  outline: none;
  transition: all 0.3s;
  cursor: pointer;
}
svg {
  display: block;
  width: 100%;
  height: 100%;
}

#main {
  position: relative;
  width: 100%;
  @include media(hover,1201){
    padding-top: 140px;
  }
  @include media(screen,1200){
    padding-top: 60px;
  }
}
#wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .footer {
    margin-top: auto;
  }

}