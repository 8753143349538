.map-box {
  &:not(:last-child){
    @include media(hover,768){
      margin-bottom: 2*$padding_md;
    }
    @include media(screen,767){
      margin-bottom: 2*$padding_sm;
    }
  }
  &--wrap {
    display: flex;
    @include media(screen,767){
      flex-direction: column;
    }
  }

  &--main {
    width: 100%;
  }

  &--aside {
    flex-shrink: 0;
    background-color: $bg;
    display: flex;
    flex-direction: column;
    @include media(hover,1201){
      width: 460px;
    }
    @include breakpoints(1025,1200){
      width: 360px;
    }
    @include breakpoints(768,1024){
      width: 45%;
    }
    @include media(screen,767){
      width: 100%;
    }
    .head {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $text_lt;
      font-size: 4rem;
      color: $border;
      font-weight: 600;
      flex-shrink: 0;
      @include media(hover,768){
        height: 60px;
        padding: 0 $padding_md;
      }
      @include media(screen,767){
        padding: 10px $padding_sm;
      }
    }
    .addresses {
      @include media(hover,768){
        height: 500px;
      }
      @include media(screen,767){
        max-height: 500px;
      }
      @include media(hover,601){
        padding: $padding_md;
      }
      @include media(screen,600){
        padding: $padding_sm;
      }
    }
    .item {
      font-size: 3.5rem;
      line-height: 1.6;
      &:not(:last-child){
        margin-bottom: $padding_md;
      }
      .name {
        font-size: 4rem;
        font-weight: 600;
      }
      .on-map {
        color: $link_dark;
        @extend %hover_underline;
      }
      &.current {
        @include media(hover,768){
          .name {
            color: $link;
          }
        }
      }
    }
    .show-all {
      display: none;
      .btn {
        height: 50px;
        padding: 0 40px;
      }
    }
    .mCS-autoHide>.mCustomScrollBox~.mCSB_scrollTools {
      opacity: 1;
    }
    @include media(hover,768){
      &.short {
        .show-all {
          display: flex;
          justify-content: center;
        }
        .item {
          display: none;
          &.current {
            display: block;
          }
        }
      }
    }
  }

  &--field {
    position: relative;
    [data-map="offices-map"]{
      width: 100%;
      height: 500px;
      @include media(screen,767){
        display: none;
      }
    }
    [data-map="settlements-map"]{
      width: 100%;
      height: 780px;
      @include media(screen,767){
        display: none;
      }
    }

  }

  #map {
    width: 100%;
    height: 100%;
  }

  img[src*=custom] {
    min-width: 36px;
    min-height: 41px;
  }

  .scaled {
    transition: all 0.12s;
    z-index: 10000 !important;
    transform: scale(1.3);
  }

}

.view-map {
  position: relative;
  .selected-items {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    z-index: 1;
    min-width: 530px;
  }
  .map-item {
    &:not(.active) {
      display: none;
    }
  }
}

.view-box {
  position: relative;
  & > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
    z-index: -2;
  }
}

#view_map:checked ~ .custom-search .view-map,
#view_list:checked ~ .custom-search .view-list {
  @include media(hover,768){
    background-color: $white;
    color: $border;
  }
}
#view_map:checked ~ .map-box--field .view-list,
#view_list:checked ~ .map-box--field .view-map {
  @include media(hover,768){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -3;
  }
}

