.about {
  &--head {
    margin-bottom: 2*$padding_sm;
    overflow: hidden;
  }
  &--wrap {
    position: relative;
    padding-top: 2*$padding_sm;
    .title {
      text-align: center;
      color: $white;
      margin-bottom: 2*$padding_sm;
    }
    .subtitle {
      font-style: italic;
      font-size: 5.2rem;
      color: $white;
      font-family: 'Merriweather';
      margin-bottom: 2*$padding_sm;
      a {
        display: inline-block;
        white-space: nowrap;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 1px;
          left: 0;
          right: 0;
          bottom: 3px;
          background-color: $white;
        }
      }
    }
  }
  &--image {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100vw;
      transform: translateX(-50vw);
      background-color: $white;
      @include media(hover,601){
        top: 200px;
      }
      @include media(screen,600){
        top: 75%;
      }
    }
    figure {
      position: relative;
      z-index: 1;
      @include media(hover,1601){
        margin: 0 -2*$padding_sm;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .img-branch {
      position: absolute;
      right: -61px;
      bottom: -60px;
      height: auto;
      @include media(hover,1201){
        width: 375px;
      }
      @include breakpoints(1025,1200){
        width: 325px;
      }
      @include media(screen,1024){
        width: 250px;
      }
      @include media(screen,767){
        display: none;
      }
    }
  }
}

.our-projects {
  position: relative;
  @include media(hover,601){
    margin-bottom: 2*$padding_md;
  }
  @include media(screen,600){
    margin-bottom: 2*$padding_sm;
  }
  .img-branch {
    position: absolute;
    bottom: 160px;
    left: 0;
    height: auto;
    @include media(hover,1367){
      width: 500px;
    }
    @include breakpoints(1025,1366){
      width: 400px;
    }
    @include media(screen,1024){
      width: 270px;
    }
    @include media(screen,767){
      display: none;
    }
  }
  &-slider {
    @include media(hover,601){
      margin-bottom: 2*$padding_md;
    }
    @include media(screen,600){
      margin-bottom: 2*$padding_sm;
    }
    .owl-prev,
    .owl-next {
      top: 50%;
    }
  }
}

.reviews {
  padding: 2*$padding_sm 0;
  background-color: $bg_blue_dark;
  overflow: hidden;
  position: relative;
  @include media(hover,601){
    margin-bottom: 2*$padding_md;
  }
  @include media(screen,600){
    margin-bottom: 2*$padding_sm;
  }
  @include media(hover,1025){
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 25%;
      background-color: $white;
    }
  }
  &-carousel {
    @include media(hover,1025){
      transform: translateX(-25%);
      margin-left: -10px;
    }
    @include breakpoints(601,1024){
      transform: translateX(-25%);
      margin-left: -5px;
    }
    &--box {
      position: relative;
      z-index: 1;
      @include media(screen,600){
        margin: 0 -20px;
      }
    }
    &--wrap {
      @include media(hover,1025){
        margin-right: -80%;
      }
      @include breakpoints(601,1024){
        margin-right: -140%;
      }
    }
    .owl-stage-outer {
      @include media(hover,1025){
        padding: 2*$padding_sm 0;
      }
      @include media(screen,1024){
        padding: 0 0 2*$padding_sm 0;
      }
    }
    .owl-stage {
      display: flex;
      align-items: stretch;
    }
    .owl-item {
      @include media(hover,601){
        &:not(.active){
          & + .active {
            .item {
              transform: translateX(-100%);
              opacity: 0;
            }
          }
        }
      }
      @include media(screen,600){
        padding: 0 20px;
      }
    }
  }
  .review-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: $padding_sm;
    .name {
      font-weight: 700;
      margin-bottom: 10px;
    }
    .position {
      color: $border;
    }
    .load {
      color: $link_hover;
    }
  }
  .review-text {
    line-height: 1.6;
    color: $text;
  }
  .item {
    font-size: 3.5rem;
    height: 100%;
    background-color: $white;
    transition: all 0.3s;
    border-radius: 0 0 5px 5px;
    border-top: 5px solid $link;
    user-select: none;
    box-shadow: 0 5px 15px 5px rgba($border,0.2);
    @include media(hover,1201){
      padding: $padding_md 2*$padding_sm 2*$padding_md;
    }
    @include media(screen,1200){
      padding: 25px $padding_md 2*$padding_sm;
    }
  }
  .owl-nav {
    display: none;
  }
  .temp-nav {
    background-color: $text;

    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-prev {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20110%20110%22%20shape-rendering%3D%22geometricPrecision%22%20image-rendering%3D%22optimizeQuality%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%3E%3Cg%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linejoin%3D%22round%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%227%22%3E%3Cpath%20d%3D%22M56%20106%206%2056%2056%206%22/%3E%3Cpath%20d%3D%22m6%2056h100%22/%3E%3C/g%3E%3C/svg%3E');
    }
    &-next {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20110%20110%22%20shape-rendering%3D%22geometricPrecision%22%20image-rendering%3D%22optimizeQuality%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%3E%3Cg%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linejoin%3D%22round%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%227%22%3E%3Cpath%20d%3D%22m55%20105l50-50-50-50%22/%3E%3Cpath%20d%3D%22m105%2055h-100%22/%3E%3C/g%3E%3C/svg%3E');
    }
    button {
      width: 28px;
      height: 28px;
      border: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 25px 25px;
      background-position: center center;
    }
    @include media(hover,1601){
      position: absolute;
      top: 0;
      bottom: 0;
      left: 60px;
      height: 60px;
      right: 50%;
      margin: auto 669px auto 0;
      padding: 0 8px;
    }
    @include media(screen,1600){
      display: flex;
      justify-content: space-between;
      width: 200px;
      margin: 0 auto;
      padding: 10px;
    }
  }
}