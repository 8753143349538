.bg-head {
  margin-bottom: $padding_sm;
  &--main {
    color: $white;
    text-align: center;
    padding: 2*$padding_md $padding_sm;
  }
  &--project {
    text-align: center;
    color: $white;
    @include media(hover,601){
      padding: 40px 20px;
    }
    @include media(screen,600){
      padding: 20px;
    }
  }
  &--text {
    color: $white;
    @include media(hover,601){
      padding: 4*$padding_sm $padding_sm;
    }
    @include media(screen,600){
      padding: 2*$padding_sm $padding_sm;
    }
  }
  &--error {
    color: $white;
    padding: 4*$padding_sm $padding_sm;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 700;
      line-height: 1;
      margin-bottom:  $padding_sm;
      @include media(hover,601){
        font-size: 50rem;
      }
      @include media(screen,600){
        font-size: 32rem;
      }
      &-sm {
        font-weight: 700;
        line-height: 1;
        @include media(hover,601){
          font-size: 20rem;
          margin-bottom: 3rem;
        }
        @include media(screen,600){
          font-size: 10rem;
          margin-bottom: 2rem;
        }
      }
    }
    .subtitle {
      font-size: 5rem;
      a {
        text-decoration: underline;
        @include hover(){
          text-decoration: none;
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      @include media(hover,601){
        margin-top: 10rem;
      }
      @include media(screen,600){
        flex-direction: column;
        align-items: stretch;
        margin-top: 5rem;
      }
      .btn {
        border: 2px solid $white;
        @include hover(){
          background-color: $white;
          color: $link_hover;
        }
        @include media(screen,600){
          margin-top: 3rem;
        }
      }
    }
    @include media(screen,767){
      min-height: calc(100vh - 175px);
    }
  }
}