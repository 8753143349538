.basket {
  &-order {
    background-color: $bg;
    .custom-row {
      @include media(screen,1600){
        display: block;
        margin: 0;
        .custom-row-col.col-elastic {
          max-width: none;
          padding: 0;
        }
        .custom-row-col.col-sidebar {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
  &-form {
    @include media(hover,1601){
      margin-bottom: 2*$padding_md;
    }
    &--section {
      padding: 2*$padding_sm 0 0;
      .title {
        margin-bottom: $padding_md;
      }
    }
    &--additional {

    }
    &--ordering {
      .ordering-data {
        background-color: $white;
        @include media(hover,1025){
          padding: $padding_sm 2*$padding_sm;
        }
        @include media(screen,1024){
          padding: 10px $padding_sm;
        }
      }
      .ordering-pays {
        border-top: 1px solid $text_lt;
        background-color: $bg_yellow;
        @include media(hover,1025){
          padding: $padding_sm 2*$padding_sm;
        }
        @include media(screen,1024){
          padding: 10px $padding_sm;
        }
      }
      .ordering-submit {
        border-top: 1px solid $text_lt;
        background-color: $bg_yellow;
        @include media(hover,1025){
          padding: $padding_sm 2*$padding_sm;
        }
        @include media(screen,1024){
          padding: 10px $padding_sm;
        }
        input {
          background-color: $white;
          height: 40px;
          line-height: 40px;
          color: $border;
          font-size: 3.5rem;
          padding: 0 20px;
          border: 1px solid RGBA(178,185,193,.5);
          outline: none;
        }
        .form-title {
          color: $text;
          margin-bottom: 15px;
        }
        .form-row-submit {
          margin-top: $padding_sm;
          margin-bottom: 10px;
        }
        &--total {
          font-size: 3.5rem;
          line-height: 1.5;
          .total-head {
            font-weight: 600;
            margin-bottom: 5px;
          }
        }
        &--title {
          font-weight: 600;
          font-size: 5.2rem;
          text-transform: uppercase;
          padding-top: 10px;
        }
        &--subtitle {
          font-weight: 600;
          font-size: 5.2rem;
        }

      }
    }
  }
}