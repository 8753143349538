.title {

  /******************************************/
  /*                H1 TITLE                */
  /******************************************/

  &.title-lg {
    font-weight: 700;
    @include media(hover,1025){font-size: 10.5rem;}
    @include breakpoints(601,1024){font-size: 9.5rem;}
    @include media(screen,600){font-size: 7.5rem;}
  }
  &.title-xl {
    font-weight: 600;
    @include media(hover,601){font-size: 10rem;}
    @include media(screen,600){font-size: 6rem;}
  }
  &.title-md {
    font-weight: 600;
    @include media(hover,601){font-size: 8rem;}
    @include media(screen,600){font-size: 6rem;}
  }
  &.title-sm {
    font-size: 6.4rem;
    font-weight: 600;
  }

}


.subtitle {

  /*********************************************/
  /*                H2 SUBTITLE                */
  /*********************************************/


}

.big-header {
  .title {

    display: flex;
    padding: 0 $padding_sm;
    align-items: center;
    justify-content: center;
    color: $border;
    text-transform: uppercase;
    font-weight: 600;
    background-color: $bg;
    @include media(hover,1025){
      font-size: 8.5rem;
    }
    @include breakpoints(601,1024){
      font-size: 7rem;
    }
    @include media(hover,601){
      height: 100px;
    }
    @include media(screen,600){
      font-size: 4.5rem;
      padding: $padding_sm;
    }
  }

  @include media(hover,1201){
    margin-bottom: 2*$padding_md;
  }
  @include breakpoints(601,1200){
    margin-bottom: 2*$padding_sm;
  }
  @include media(screen,600){
    margin-bottom: $padding_sm;
  }


}