.blog {
  &-hashes {
    display: flex;
    flex-flow: row wrap;
    .btn-small {
      border-color: $text_lt;
      margin: 4px 0;
      @include hover(){
        background-color: $border;
        border-color: $border;
      }
      &.active {
        background-color: $border;
        border-color: $border;
        color: $white;
      }
    }
  }
  &-list {
    margin: 0 -1*$padding_sm;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    &.col-3 {
      .blog-item {
        @include media(hover,1201){
          width: 33.3333%;
        }
        @include breakpoints(768,1200){
          width: 50%;
        }
        @include media(screen,767){
          width: 100%;
        }
      }
    }
  }
  &-item {
    padding: 0 $padding_sm;
    @include hover(){
      .blog-item--header {
        box-shadow: 0 5px 20px 5px rgba($border,0.16);
      }
    }
    &--wrap {
      width: 100%;
      display: block;
    }
    &--figure {
      width: 100%;
      @extend %cover;
      @include media(hover,1601){
        height: 300px;
      }
      @include media(screen,1600){
        height: 0;
        padding-top: 60%;
      }
    }
    &--header {
      background-color: $white;
      border-left: 5px solid $link;
      padding: 0.5*$padding_md $padding_sm;
      box-shadow: 0 2px 20px 5px rgba($border,0.1);
      transition: all 0.3s;
      @include media(hover,1367){
        margin: -2*$padding_sm 2*$padding_sm 2*$padding_sm;
      }
      @include media(screen,1366){
        margin: -2*$padding_sm $padding_sm $padding_md;
      }
    }
    &--date {
      color: $border;
      display: block;
      font-size: 3.5rem;
      margin-bottom: 5px;
    }
    &--title {
      color: $text;
      font-size: 4rem;
      font-weight: 600;
      text-transform: uppercase;
      @include hover(){
        color: $link_hover;
      }
    }
  }
}