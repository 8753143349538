.article-page {
  @include media(hover,768){
    padding: 2*$padding_sm 0 ;
  }
  @include media(screen,767){
    padding: 2*$padding_sm 0 $padding_sm;
  }
  &--header {
    margin-bottom: 2*$padding_sm;
    @include media(hover,1025){
      padding-right: 15%;
    }
    .title {
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: $padding_md;
      @include media(hover,768){
        font-size: 10.5rem;
      }
      @include breakpoints(601,767){
        font-size: 8rem;
      }
      @include media(screen,600){
        font-size: 6rem;
      }
    }
    .auth {
      display: flex;
      align-items: center;
      font-size: 3.5rem;
      color: $border;
      .ava {
        width: 60px;
        height: 60px;
        margin-right: $padding_sm;
        img {
          @extend %fullBlock;
          border-radius: 50%;
        }
      }
      .name {
        margin-right: $padding_md;
      }
    }
  }
  &--main {
    width: 100%;
    padding-bottom: $padding_sm;
    @include media(hover,1025){
      max-width: 80%;
      .text-box {
        figure {
          margin-right: -25%;
        }
      }
    }
  }
  .catalog-actions {
    @include media(hover,1025){
      max-width: 80%;
    }
  }
  &--sidebar {
    .title {
      text-transform: uppercase;
      font-size: 4rem;
      color: $border;
      font-weight: 600;
      margin-bottom: 40px;
    }
    .projects-item {
      margin-bottom: 10px;
      @media screen and (min-width: 661px) and (max-height: 880px) {
        &:nth-child(3){display: none;}
      }
      @media screen and (max-height: 660px) {
        &:nth-child(2),
        &:nth-child(3){display: none;}
      }
    }
  }
}