.projects-slider {
  background-color: $bg;
  padding: 2*$padding_sm 0 $padding_sm;
  @include media(hover,768){
    margin-top: 2*$padding_md;
  }
  @include media(screen,767){
    margin-top: 2*$padding_sm;
  }
  .title {
    text-transform: uppercase;
    font-size: 4rem;
    color: $border;
    font-weight: 600;
    margin-bottom: $padding_sm;
  }
  &--carousel {
    .owl-stage-outer {
      padding: $padding_sm 0;
    }
    .owl-prev,
    .owl-next {
      top: 70px;
    }
  }
  &--item {
    &-figure {
      height: 300px;
      width: 100%;
      @extend %cover;
    }
    &-header {
      background-color: $white;
      margin: -2*$padding_sm 2*$padding_sm 2*$padding_sm;
      border-left: 5px solid $link;
      padding: 0.5*$padding_md $padding_sm;
      box-shadow: 0 5px 20px 5px rgba($border,0.16);
    }
    &-date {
      color: $border;
      display: block;
      font-size: 3.5rem;
      margin-bottom: 5px;
    }
    &-title {
      color: $text;
      font-size: 4rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.blog-slider.simple,
.like-slider,
.basket-order,
.compare-page,
.request,
.info-box {
  & + .projects-slider {
    margin-top: 0;
  }
}
