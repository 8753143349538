.blog-slider {
  &.simple {
    padding: 2*$padding_sm 0 $padding_sm;
  }
  &.full {
    padding: 2*$padding_sm 0;
  }
  .title {
    text-transform: uppercase;
    font-size: 4rem;
    color: $border;
    font-weight: 600;
    margin-bottom: 2*$padding_sm;
  }
  &--carousel {
    .owl-prev,
    .owl-next {
      @include media(hover,1367){
        top: 150px;
      }
      @include breakpoints(1025,1366){
        top: 110px;
      }
      @include breakpoints(768,1024){
        top:13vw;
      }
      @include media(screen,767){
        top:26vw;
      }
    }
  }
  &--item {
    display: block;
    @include hover(){
      .blog-slider--item-header {
        box-shadow: 0 5px 20px 5px rgba($border,0.16);
      }
    }
    &-figure {
      width: 100%;
      @extend %cover;
      @include media(hover,1367){
        height: 300px;
      }
      @include breakpoints(1201,1366){
        height: 220px;
      }
      @include media(screen,1200){
        padding-top: 60%;
        height: 0;
      }
    }
    &-header {
      background-color: $white;
      border-left: 5px solid $link;
      padding: 0.5*$padding_md $padding_sm;
      box-shadow: 0 2px 20px 5px rgba($border,0.1);
      transition: all 0.3s;
      @include media(hover,1367){
        margin: -2*$padding_sm 2*$padding_sm 2*$padding_sm;
      }
      @include media(screen,1366){
        margin: -2*$padding_sm $padding_sm $padding_md;
      }
    }
    &-date {
      color: $border;
      display: block;
      font-size: 3.5rem;
      margin-bottom: 5px;
    }
    &-title {
      color: $text;
      font-size: 4rem;
      font-weight: 600;
      text-transform: uppercase;
      @include hover(){
        color: $link_hover;
      }
    }
  }
}