.login-form {
  position: relative;
  @include media(hover,768){
    padding-bottom: 2*$padding_md;
  }
  @include media(screen,767){
    padding-bottom: 2*$padding_sm;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    width: 100vw;
    transform: translateX(-50vw);
    background-color: $white;
  }
  &--wrap {
    background-color: $white;
    position: relative;
    box-shadow: 0 30px 80px 0 rgba(26,26,26,0.2);
  }
  .successful & {
    display: none;
  }
}

.site-login {

}