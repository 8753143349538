.counter {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  .minus,
  .plus {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: $link_dark;
    &.disabled {
      pointer-events: none;
      color: $border;
    }
  }
  .result {
    border: none;
    text-align: center;
    display: inline-block;
    width: 50px;
    padding: 0 10px;
    cursor: default;
    height: 20px;
    font-size: 3.5rem;
    font-weight: 700;
  }
}

