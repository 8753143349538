.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: all 0.35s;
  opacity: 0;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black,0.5);
  }
  &-title {
    font-size: 5.2rem;
    text-align: center;
    font-weight: 600;
    @include media(hover,601){
      padding: 2*$padding_sm 100px $padding_sm 100px;
    }
    @include media(screen,600){
      padding: 2*$padding_sm $padding_sm $padding_sm;
    }
  }
  &-custom {
    z-index: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.6;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 4rem;
    transition: all 0.2s;
    min-height: 250px;
    @include media(hover,601){
      padding: $padding_md 100px;
    }
    @include media(screen,600){
      padding: $padding_md $padding_sm;
    }
    &--title {
      font-size: 6.4rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  &-thanks {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.6;
    background-color: $white;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 4rem;
    transition: all 0.2s;
    @include media(hover,601){
      padding: $padding_md 100px;
    }
    @include media(screen,600){
      padding: $padding_md $padding_sm;
    }
    &--title {
      font-size: 6.4rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .successful & {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &-box {
    position: absolute;
    max-width: 600px;
    width: 100%;
    background-color: $white;
    max-height: 100vh;
    overflow: auto;
    @include media(hover,601){
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    @include media(screen,600){
      left: 0;
      top: 0;
      //bottom: 0;
      right: 0;
      padding-bottom: 20px;
      overflow: auto;
    }
    .mobile & {
      @include media(hover,601){
        transform: translate(0,0);
        left: 0;
        top: 0;
        right: 0;
        margin: auto;
      }
    }
    .close {
      position: absolute;
      top: 10px;
      right: 12px;
      color: $border;
      font-weight: 100;
      cursor: pointer;
      z-index: 3;
      @include media(hover,601){
        width: 30px;
        height: 30px;
        font-size: 60px;
        line-height: 30px;
      }
      @include media(screen,600){
        width: 25px;
        height: 25px;
        font-size: 45px;
        line-height: 25px;
      }
    }
  }
  &-content {
    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      @include media(hover,601){
        padding: 1.5*$padding_sm 50px 2.5*$padding_sm 50px;
      }
      @include media(screen,600){
        padding: $padding_sm $padding_sm 2*$padding_sm;
      }
    }
    .link {
      color: $link_dark;
      @extend %hover_underline;
      margin: 0 10px;
      @include hover() {
        color: $link_dark * 0.8;
      }
    }
    .btn-square-min {
      width: auto;
      margin: 0 10px;
    }
  }
  &-form {
    &--fields {

      @include media(hover,601){
        padding: 0 100px $padding_md;
      }
      @include media(screen,600){
        padding: 0 $padding_sm $padding_md;
      }
    }
    &--submit {
      @include media(screen,600){
        padding: 0 $padding_sm;
      }
      .btn {
        width: 100%;
      }
    }
  }

}